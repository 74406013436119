import {httpClient} from "../_helpers";

export const contactService = {
    getByServicePartnerId,
};

function getByServicePartnerId(id) {
    return httpClient
        .get(`/contact/servicepartner/${id}`)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
