export const fileConstants = {
  GET_ALL_REQUEST: "FILE_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "FILE_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "FILE_GET_ALL_FAILURE",

  GET_BYID_REQUEST: "FILE_GET_BYID_REQUEST",
  GET_BYID_SUCCESS: "FILE_GET_BYID_SUCCESS",
  GET_BYID_FAILURE: "FILE_GET_BYID_FAILURE",

  SUBMISSION_DOCUMENT_UPLOAD_REQUEST: "FILE_SUBMISSION_UPLOAD_REQUEST",
  SUBMISSION_DOCUMENT_UPLOAD_SUCCESS: "FILE_SUBMISSION_UPLOAD_SUCCESS",
  SUBMISSION_DOCUMENT_UPLOAD_FAILURE: "FILE_SUBMISSION_UPLOAD_FAILURE",

  GET_ALL_SUBMISSION_REQUEST: "FILE_GET_ALL_SUBMISSION_REQUEST",
  GET_ALL_SUBMISSION_SUCCESS: "FILE_GET_ALL_SUBMISSION_SUCCESS",
  GET_ALL_SUBMISSION_FAILURE: "FILE_GET_ALL_SUBMISSION_FAILURE",

  SUBMISSION_DOCUMENT_DELETE_REQUEST: "FILE_SUBMISSION_DELETE_REQUEST",
  SUBMISSION_DOCUMENT_DELETE_SUCCESS: "FILE_SUBMISSION_DELETE_SUCCESS",
  SUBMISSION_DOCUMENT_DELETE_FAILURE: "FILE_SUBMISSION_DELETE_FAILURE",

  SUBMISSION_DOCUMENTS_CLEAR: "FILE_SUBMISSION_CLEAR",
};
