import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Teal from "@material-ui/core/colors/teal";
import { Provider } from "react-redux";
import { store } from "./_helpers";
import "./i18n";

const theme = createMuiTheme({
  palette: {
    primary: Teal,
  },
  overrides: {
    MuiTableCell: {
      head: {
        fontWeight: "bold",
      },
      root: {
        padding: "8px 7px",
        fontSize: "0.85rem",
        "&:last-child": { paddingRight: 0 },
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "0.85rem",
      },
    },
    MuiIconButton: {
      root: {
        padding: "12px 3px",
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: "50px !important",
      },
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Suspense fallback={<div>Loading...</div>}>
        <App />
      </Suspense>
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
