import { httpClient } from "../_helpers";

export const claimService = {
  getClaimsBySubmission,
  getMaterialCategories,
  getContainerTypes,
  getBulbTypes,
  getUnits,
  getServiceTypes,
  postClaim,
  updateClaim,
  deleteClaim,
};

function getClaimsBySubmission(id) {
  return httpClient
    .get(`/claim/submission/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function getMaterialCategories() {
  return httpClient
    .get(`/claim/materialcategories`)
    .then((response) => {
      var arr = response.data;
      //   var obj = arr.reduce(function (acc, cur, i) {
      //     acc[cur.code] = cur.value;

      //     return acc;
      //   }, {});
      return arr;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function getContainerTypes(id) {
  return httpClient
    .get(`/claim/containertypes`)
    .then((response) => {
      var arr = response.data;
      //   var obj = arr.reduce(function (acc, cur, i) {
      //     acc[cur.code] = cur.value;

      //     return acc;
      //   }, {});
      return arr;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function getBulbTypes() {
  return httpClient
    .get(`/claim/bulbtypes`)
    .then((response) => {
      var arr = response.data;
      //   var obj = arr.reduce(function (acc, cur, i) {
      //     acc[cur.code] = cur.value;

      //     return acc;
      //   }, {});
      return arr;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function getUnits(id) {
  return httpClient
    .get(`/claim/units`)
    .then((response) => {
      var arr = response.data;
      //   var obj = arr.reduce(function (acc, cur, i) {
      //     acc[cur.code] = cur.value;

      //     return acc;
      //   }, {});
      return arr;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function getServiceTypes(id) {
  return httpClient
    .get(`/claim/servicetypes`)
    .then((response) => {
      var arr = response.data;
      // var obj = arr.reduce(function(acc, cur, i) {
      //     acc[cur.code] = cur.value;

      //     return acc;
      //   }, {});
      return arr;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function postClaim(item, submissionId) {
  return httpClient
    .post(`/claim/create/${submissionId}`, item)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function updateClaim(item) {
  return httpClient
    .put("/claim/update", item)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function deleteClaim(id) {
  return httpClient
    .delete(`/claim/delete/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
