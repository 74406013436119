import React from "react";
import MaterialTable from "material-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import { forwardRef } from "react";
import { Paper } from "@material-ui/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Link from "@material-ui/core/Link";
import {
  amber,
  blue,
  red,
  green,
  orange,
  grey,
  blueGrey,
} from "@material-ui/core/colors";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} style={{ color: blueGrey[500] }} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const FilesTable = (props) => {
  const columns = [
    {
      title: "Name",
      field: "documentName",
      render: (rowData) => (
        <Link target="_blank" href={rowData.documentUrl}>
          {rowData.documentName}
        </Link>
      ),
    },
  ];

  return (
    <React.Fragment>
      {/* {(props.isLoading || props.isUploading) && (
        <CircularProgress
          size={20}
          style={{ position: "absolute", zIndex: "999999" }}
        />
      )} */}
      <MaterialTable
        icons={tableIcons}
        isLoading={props.isLoading || props.isUploading}
        title=""
        localization={{
          body: {
            editRow: {
              deleteText: "Are you sure you want to delete this file?",
            },
          },
        }}
        columns={columns}
        data={props.data}
        onRowClick={props.onRowClick}
        editable={{
          onRowDelete: (oldData) => props.rowDelete(oldData),
        }}
        options={{
          actionsColumnIndex: -1,
          maxBodyHeight: 147,
          paging: props.paging,
        }}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        actions={[
          {
            icon: () => <AddBox color="primary" />,
            tooltip: "Add Files",
            // This makes add button to appear in table toolbar instead for each row
            isFreeAction: true,
            onClick: (event, rowData) => {
              props.rowAdd();
            },
          },
        ]}
      />
    </React.Fragment>
  );
};

export default FilesTable;
