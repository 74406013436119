import React, { useState, useEffect } from "react";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2, 2, 8),
    overflowY: "hidden",
    overflowX: "hidden",
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

const ForgotPassword = () => {

  const [isForm, SetIsForm] = useState(false);
  const classes = useStyles();

  const isFormSubmitted = () => {
    SetIsForm(true);
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={12}>
        {!isForm ? (
          <ForgotPasswordForm isFormSubmitted={isFormSubmitted} />
          ) : (
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Paper className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <ErrorOutlineIcon />
                </Avatar>
                <Typography component="h6" variant="h6">
                  Success!
                </Typography>
                <Typography>
                  Your request for password reset has been submitted. You will be receiving an email with 
                  further instructions
                </Typography>
              </Paper>
            </Container>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ForgotPassword;
