import React from "react";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Footer from "./components/sections/Footer";
import Header from "./components/sections/header/Header";
import Home from "./components/pages/Home";

import Account from "./components/pages/account/Account";
import Contact from "./components/pages/Contact";
import History from "./components/pages/history/History";
import UploadFiles from "./components/pages/submissions/UploadFiles";
import Submissions from "./components/pages/submissions/Submissions";
import { PrivateRoute } from "./components/privateRoute";
import { ToastContainer } from "react-toastify";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";

const App = () => {
  return (
    <BrowserRouter>
      <div id="page-container">
        <Header />
        <main>
          <Switch>
            <Route path="/" component={Home} exact />
            <PrivateRoute path="/account" component={Account} />
            <Route path="/contact" component={Contact} />
            <PrivateRoute path="/history" component={History} />
            <PrivateRoute path="/submissions" component={Submissions} />
            <Route path="/forgotPassword" component={ForgotPassword} />
            <Route path="/resetPassword/:id/:code" component={ResetPassword} />
            <Route path="/uploadFiles/:submissionNumber" component={UploadFiles} />
          </Switch>
        </main>
        <Footer />
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </BrowserRouter>
  );
};

export default App;
