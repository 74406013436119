export const claimConstants = {
    CLAIM_GET_BYSUBMISSION_REQUEST: "CLAIM_GET_BYSUBMISSION_REQUEST",
    CLAIM_GET_BYSUBMISSION_SUCCESS: "CLAIM_GET_BYSUBMISSION_SUCCESS",
    CLAIM_GET_BYSUBMISSION_FAILURE: "CLAIM_GET_BYSUBMISSION_FAILURE",
    CLAIM_GET_BYSUBMISSION_FS_REQUEST: "CLAIM_GET_BYSUBMISSION_FS_REQUEST",
    CLAIM_GET_BYSUBMISSION_FS_SUCCESS: "CLAIM_GET_BYSUBMISSION_FS_SUCCESS",
    CLAIM_GET_BYSUBMISSION_FS_FAILURE: "CLAIM_GET_BYSUBMISSION_FS_FAILURE",
    CLAIM_GET_MATERIALCATEGORIES_REQUEST: "CLAIM_GET_MATERIALCATEGORIES_REQUEST",
    CLAIM_GET_MATERIALCATEGORIES_SUCCESS: "CLAIM_GET_MATERIALCATEGORIES_SUCCESS",
    CLAIM_GET_MATERIALCATEGORIES_FAILURE: "CLAIM_GET_MATERIALCATEGORIES_FAILURE",
    CLAIM_GET_CONTAINERTYPES_REQUEST: "CLAIM_GET_CONTAINERTYPES_REQUEST",
    CLAIM_GET_CONTAINERTYPES_SUCCESS: "CLAIM_GET_CONTAINERTYPES_SUCCESS",
    CLAIM_GET_CONTAINERTYPES_FAILURE: "CLAIM_GET_CONTAINERTYPES_FAILURE",
    CLAIM_GET_BULBTYPES_REQUEST: "CLAIM_GET_BULBTYPES_REQUEST",
    CLAIM_GET_BULBTYPES_SUCCESS: "CLAIM_GET_BULBTYPES_SUCCESS",
    CLAIM_GET_BULBTYPES_FAILURE: "CLAIM_GET_BULBTYPES_FAILURE",
    CLAIM_GET_SERVICETYPES_REQUEST: "CLAIM_GET_SERVICETYPES_REQUEST",
    CLAIM_GET_SERVICETYPES_SUCCESS: "CLAIM_GET_SERVICETYPES_SUCCESS",
    CLAIM_GET_SERVICETYPES_FAILURE: "CLAIM_GET_SERVICETYPES_FAILURE",
    CLAIM_GET_UNITS_REQUEST: "CLAIM_GET_UNITS_REQUEST",
    CLAIM_GET_UNITS_SUCCESS: "CLAIM_GET_UNITS_SUCCESS",
    CLAIM_GET_UNITS_FAILURE: "CLAIM_GET_UNITS_FAILURE",

    POST_CLAIM_REQUEST: "POST_CLAIM_REQUEST",
    POST_CLAIM_SUCCESS: "POST_CLAIM_SUCCESS",
    POST_CLAIM_FAILURE: "POST_CLAIM_FAILURE",

    PUT_CLAIM_REQUEST: "PUT_CLAIM_REQUEST",
    PUT_CLAIM_SUCCESS: "PUT_CLAIM_SUCCESS",
    PUT_CLAIM_FAILURE: "PUT_CLAIM_FAILURE",

    DELETE_CLAIM_REQUEST: "DELETE_CLAIM_REQUEST",
    DELETE_CLAIM_SUCCESS: "DELETE_CLAIM_SUCCESS",
    DELETE_CLAIM_FAILURE: "DELETE_CLAIM_FAILURE",
};
