import { contactConstants } from "../_constants";

const initialState = {
    items: [],
    loading: false,
    error: null,
};

export function contacts(state = initialState, action) {
    switch (action.type) {
        case contactConstants.GET_ALL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case contactConstants.GET_ALL_SUCCESS:
            return {
                ...state,
                items: action.items,
                loading: false,
            };
        case contactConstants.GET_ALL_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        default:
            return state;
    }
}
