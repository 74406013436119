import { userConstants } from "../_constants";

let user = JSON.parse(localStorage.getItem("user"));
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggingIn: false,
        loggedIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        loggingIn: false,
      };
    case userConstants.LOGOUT:
      return {};
    default:
      return state;
  }
}

// let auth = localStorage.getItem("auth");
// let token = localStorage.getItem("token");

// const initialState = auth ? { loggedIn: true, auth, token } : {};

// export function authentication(state = initialState, action) {
//     switch (action.type) {
//         case "LOGIN_SUCCESS":
//             return {
//                 loggedIn: true,
//                 auth: action.auth,
//                 token: action.token,
//             };
//         case "LOGOUT_SUCCESS":
//             return {
//                 auth: false,
//             };
//         default:
//             return state;
//     }
// }
