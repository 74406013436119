import { submissionConstants } from "../_constants";

const initialState = {
  details: {},
  items: [],
  selected: {},
  loading: false,
  detailsLoading: false,
  detailsError: false,
  error: null,
};

export function historySubmissions(state = initialState, action) {
  switch (action.type) {
    case submissionConstants.SUBMISSION_HISTORY_GET_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case submissionConstants.SUBMISSION_HISTORY_GET_LIST_SUCCESS:
      return {
        ...state,
        items: action.submissions,
        loading: false,
      };
    case submissionConstants.SUBMISSION_HISTORY_GET_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case submissionConstants.SUBMISSION_HISTORY_GET_BYID_REQUEST:
      return {
        ...state,
        detailsLoading: true,
      };
    case submissionConstants.SUBMISSION_HISTORY_GET_BYID_SUCCESS:
      return {
        ...state,
        details: action.submission,
        detailsLoading: false,
      };
    case submissionConstants.SUBMISSION_HISTORY_GET_BYID_FAILURE:
      return {
        ...state,
        detailsError: action.error,
        detailsLoading: false,
      };
    case submissionConstants.SUBMISSION_HISTORY_UPDATE_SELECTED:
      return {
        ...state,
        selected: action.submission,
      };

    default:
      return state;
  }
}
