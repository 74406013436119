import React from "react";
import MaterialTable from "material-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import { forwardRef } from "react";
import { Paper } from "@material-ui/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ViewColumn from "@material-ui/icons/ViewColumn";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const FilesList = (props) => {
  return (
    <React.Fragment>
      {/* {props.isLoading && (
        <CircularProgress
          size={20}
          style={{ position: "absolute", zIndex: "999999", left: "95%" }}
        />
      )} */}
      <MaterialTable
        icons={tableIcons}
        isLoading={props.isLoading}
        title=""
        columns={props.columns}
        data={props.data}
        options={{
          actionsColumnIndex: -1,
          paging: props.paging,
          search: false,
          toolbar: false,
          maxBodyHeight: 141,
        }}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
      />
    </React.Fragment>
  );
};

export default FilesList;

// import React from "react";
// import { makeStyles, withStyles } from "@material-ui/core/styles";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
// import Paper from "@material-ui/core/Paper";
// import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
// import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";

// const useStyles = makeStyles((theme) => ({
//     root: {
//         flexGrow: 1,
//         padding: theme.spacing(2),
//         overflowY: "auto",
//         overflowX: "hidden",
//         height: 100
//     },
// }));

// const FilesList = ({ files }) => {
//     console.log("files contains in filesList:");
//     console.log(files);
//     const classes = useStyles();
//     return (
//         <React.Fragment>
//             <TableContainer component={Paper} elevation={0} className={classes.root}>
//                 <Table
//                     aria-label="Files"
//                     size="small"
//                 >
//                     <TableHead>
//                         <TableRow>
//                             <TableCell align="center">Name</TableCell>
//                             <TableCell align="center">Active</TableCell>
//                         </TableRow>
//                     </TableHead>
//                     <TableBody>
//                         {files.map((row) => (
//                             <TableRow key={row.documentId}>
//                                 <TableCell
//                                     component="th"
//                                     scope="row"
//                                     align="center"
//                                 >
//                                     <a href={row.documentUrl}>{row.documentName}</a>
//                                 </TableCell>
//                                 <TableCell align="center">
//                                     {row.status === 1 ? (
//                                         <CheckCircleOutlineIcon color="primary" />
//                                     ) : (
//                                         <CancelOutlinedIcon color="primary" />
//                                     )}
//                                 </TableCell>
//                             </TableRow>
//                         ))}
//                     </TableBody>
//                 </Table>
//             </TableContainer>
//         </React.Fragment>
//     );
// };

// export default FilesList;
