import { locationConstants } from "../_constants";
import { locationService } from "../_services";

export const locationActions = {
    getByServicePartnerId,
    getCollectionSites,
};

function getByServicePartnerId(id) {
    return (dispatch) => {
        dispatch(request());

        locationService.getByServicePartnerId(id).then(
            (items) => dispatch(success(items)),
            (error) =>
                dispatch(failure("ERROR: Failed to fetch account locations."))
        );
    };

    function request() {
        return { type: locationConstants.GET_ALL_REQUEST };
    }
    function success(items) {
        return { type: locationConstants.GET_ALL_SUCCESS, items };
    }
    function failure(error) {
        return { type: locationConstants.GET_ALL_FAILURE, error };
    }
}

function getCollectionSites(id) {
    return (dispatch) => {
        dispatch(request());

        locationService.getCollectionSites(id).then(
            (items) => dispatch(success(items)),
            (error) =>
                dispatch(failure("ERROR: Failed to fetch collection sites."))
        );
    };

    function request() {
        return { type: locationConstants.GET_COLLECTIONSITES_REQUEST };
    }
    function success(items) {
        return { type: locationConstants.GET_COLLECTIONSITES_SUCCESS, items };
    }
    function failure(error) {
        return { type: locationConstants.GET_COLLECTIONSITES_FAILURE, error };
    }
}
