import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { announcementActions } from "../_actions";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import ListSubheader from "@material-ui/core/ListSubheader";
import Container from "@material-ui/core/Container";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AnnouncementOutlinedIcon from "@material-ui/icons/AnnouncementOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  subHeader: {
    padding: theme.spacing(8, 2, 2, 2),
  },
}));

const AnnouncementsList = () => {
  const dispatch = useDispatch();
  const { loading, items } = useSelector((state) => state.announcements);
  const announcements = items.map((item) => <li>{item.title}</li>);

  useEffect(() => {
    console.log("effect");
    dispatch(announcementActions.getAnnouncements());
  }, []);

  const classes = useStyles();

  return (
    <React.Fragment>
      <Container maxWidth="sm" className={classes.subHeader}>
        <Typography variant="h5" color="textSecondary" paragraph>
          Portal Alerts
        </Typography>

        <List>
          <Divider></Divider>
          {items.map((item, i) => {
            return (
              <React.Fragment key={i}>
                <ListItem>
                  <ListItemIcon>
                    <AnnouncementOutlinedIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <div dangerouslySetInnerHTML={{ __html: item.body }} />
                    }
                  />
                </ListItem>
              </React.Fragment>
            );
          })}
        </List>
      </Container>
    </React.Fragment>
  );
};

export default AnnouncementsList;
