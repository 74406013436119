import { claimConstants, submissionConstants } from "../_constants";

const initialState = {
  hcClaims: [],
  hcLoading: false,
  hcError: null,
};

const scInitialState = {
  scClaims: [],
  scLoading: false,
  scError: null,
  scPostError: null,
  scPutError: null,
  scDeleteError: null,
};

export function claims(state = initialState, action) {
  switch (action.type) {
    case claimConstants.CLAIM_GET_BYSUBMISSION_REQUEST:
      return {
        ...state,
        hcLoading: true,
      };
    case claimConstants.CLAIM_GET_BYSUBMISSION_SUCCESS:
      return {
        ...state,
        hcClaims: action.claims,
        hcLoading: false,
        hcError: null,
      };
    case claimConstants.CLAIM_GET_BYSUBMISSION_FAILURE:
      return {
        ...state,
        hcError: action.error,
        hcLoading: false,
      };
    default:
      return state;
  }
}

export function submissionClaims(state = scInitialState, action) {
  switch (action.type) {
    case claimConstants.CLAIM_GET_BYSUBMISSION_FS_REQUEST:
      return {
        ...state,
        scLoading: true,
      };
    case claimConstants.CLAIM_GET_BYSUBMISSION_FS_SUCCESS:
      return {
        ...state,
        scClaims: action.claims,
        scLoading: false,
        scError: null,
      };
    case claimConstants.CLAIM_GET_BYSUBMISSION_FS_FAILURE:
      return {
        ...state,
        scError: action.error,
        scLoading: false,
      };
    case claimConstants.POST_CLAIM_REQUEST:
      return { ...state, scPostError: null };
    case claimConstants.POST_CLAIM_FAILURE:
      return { ...state, scPostError: action.error };
    case claimConstants.PUT_CLAIM_REQUEST:
      return { ...state, scPutError: null };
    case claimConstants.PUT_CLAIM_FAILURE:
      return { ...state, scPutError: action.error };
    case claimConstants.DELETE_CLAIM_REQUEST:
      return { ...state, scDeleteError: null };
    case claimConstants.DELETE_CLAIM_FAILURE:
      return { ...state, scDeleteError: action.error };
    case submissionConstants.DELETE_UNSUBMITTED_SUCCESS:
    case submissionConstants.SUBMIT_UNSUBMITTED_SUCCESS:
      return {
        ...state,
        scClaims: [],
      };
    default:
      return state;
  }
}

const mcInitialState = {
  list: [],
  loading: false,
  error: null,
};

export function materialCategories(state = mcInitialState, action) {
  switch (action.type) {
    case claimConstants.CLAIM_GET_MATERIALCATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case claimConstants.CLAIM_GET_MATERIALCATEGORIES_SUCCESS:
      return {
        ...state,
        list: action.items,
        loading: false,
        error: null,
      };
    case claimConstants.CLAIM_GET_MATERIALCATEGORIES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

const ctInitialState = {
  list: [],
  loading: false,
  error: null,
};

export function containerTypes(state = ctInitialState, action) {
  switch (action.type) {
    case claimConstants.CLAIM_GET_CONTAINERTYPES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case claimConstants.CLAIM_GET_CONTAINERTYPES_SUCCESS:
      return {
        ...state,
        list: action.items,
        loading: false,
        error: null,
      };
    case claimConstants.CLAIM_GET_CONTAINERTYPES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

const btInitialState = {
  list: [],
  loading: false,
  error: null,
};

export function bulbTypes(state = btInitialState, action) {
  switch (action.type) {
    case claimConstants.CLAIM_GET_BULBTYPES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case claimConstants.CLAIM_GET_BULBTYPES_SUCCESS:
      return {
        ...state,
        list: action.items,
        loading: false,
        error: null,
      };
    case claimConstants.CLAIM_GET_BULBTYPES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

const stInitialState = {
  list: [],
  loading: false,
  error: null,
};

export function serviceTypes(state = stInitialState, action) {
  switch (action.type) {
    case claimConstants.CLAIM_GET_SERVICETYPES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case claimConstants.CLAIM_GET_SERVICETYPES_SUCCESS:
      return {
        ...state,
        list: action.items,
        loading: false,
        error: null,
      };
    case claimConstants.CLAIM_GET_SERVICETYPES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

const unitsInitialState = {
  list: [],
  loading: false,
  error: null,
};

export function units(state = unitsInitialState, action) {
  switch (action.type) {
    case claimConstants.CLAIM_GET_UNITS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case claimConstants.CLAIM_GET_UNITS_SUCCESS:
      return {
        ...state,
        list: action.items,
        loading: false,
        error: null,
      };
    case claimConstants.CLAIM_GET_UNITS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
