import React, { useState } from "react";
import MaterialTable from "material-table";
import { toast } from "react-toastify";
import { forwardRef } from "react";
import { Paper } from "@material-ui/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import CircularProgress from "@material-ui/core/CircularProgress";
import Archive from "@material-ui/icons/Archive";
import ConfirmDialog from "../../ConfirmDialog";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ReportRoundedIcon from "@material-ui/icons/ReportRounded";
import {
  amber,
  blue,
  red,
  green,
  lightGreen,
  orange,
  deepOrange,
  grey,
  blueGrey,
  cyan,
  indigo,
  teal,
} from "@material-ui/core/colors";

const tableIcons = {
  Add: forwardRef((props, ref) => (
    <AddBox {...props} ref={ref} color="primary" data-cy="add" />
  )),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => (
    <Edit {...props} ref={ref} style={{ color: blueGrey[500] }} />
  )),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(8),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const UnfinishedSubmissionsTable = (props) => {
  //const [selectedRow, setSelectedRow] = useState(null);
  const [submitConfirmOpen, setSubmitConfirmOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  //const [activeSubmission, setActiveSubmission] = useState({});

  const columns = [
    {
      title: "Note",
      field: "note",
      editable: "never",
      width: "50px",
      render: (rowData) =>
        rowData &&
        rowData.notes && (
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit" variant="body2" gutterBottom>
                  <span dangerouslySetInnerHTML={{ __html: rowData.notes }} />
                </Typography>
              </React.Fragment>
            }
          >
            <ReportRoundedIcon style={{ color: amber[500] }} />
          </HtmlTooltip>
        ),
    },
    {
      title: "Title",
      field: "name",
    },
    {
      title: "Date",
      field: "submissionDate",
      type: "date",
      editable: "never",
    },
    {
      title: "Entered By",
      field: "contactFullName",
      editable: "never",
    },
  ];
  const onSelectedRow = (event, selectedRow) => {
    //setSelectedRow(selectedRow.tableData.id);
    //setSelectedRow(selectedRow);
    props.onRowClick(selectedRow);
  };

  const validateRowAdd = (name) => {
    if (name && name.trim().length > 0) {
      return true;
    }

    toast.error("Invalid submission Title!", {
      toastId: "submission-save-error",
    });

    return false;
  };

  const onSubmitConfirmHandler = () => {
    props.onRowSubmit();
  };

  const onDeleteConfirmHandler = () => {
    props.rowDelete();
  };

  return (
    <React.Fragment>
      {/* {props.isLoading && (
        <CircularProgress
          size={20}
          style={{ position: "absolute", zIndex: "999999" }}
        />
      )} */}
      <MaterialTable
        icons={tableIcons}
        isLoading={props.isLoading}
        title=""
        localization={{
          body: {
            editRow: {
              deleteText: "Are you sure you want to delete this submission?",
            },
          },
        }}
        columns={columns}
        data={props.data}
        onRowClick={onSelectedRow}
        editable={{
          isEditable: (rowData) => rowData.id === props.selected?.id,
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              if (validateRowAdd(newData.name)) {
                resolve();
                props.onRowAdd(newData);
              } else {
                reject();
              }
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              if (validateRowAdd(newData.name)) {
                resolve();
                props.onRowUpdate(newData, oldData);
              } else {
                reject();
              }
            }),
        }}
        options={{
          actionsColumnIndex: -1,
          addRowPosition: "first",
          paging: props.paging,
          maxBodyHeight: 145,
          rowStyle: (rowData) => ({
            backgroundColor:
              //selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
              props.selected?.id === rowData.id ? "#EEE" : "#FFF",
          }),
        }}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        actions={[
          (rowData) => ({
            icon: () => <Archive style={{ color: "#00c853" }} />,
            tooltip: "Submit Submission",
            onClick: (event, rowData) => {
              setSubmitConfirmOpen(true);
              //setActiveSubmission(rowData);
            },
            //disabled: rowData.tableData.id !== selectedRow,
            disabled: rowData.id !== props.selected?.id,
          }),
          (rowData) => ({
            icon: () => <DeleteOutline style={{ color: blueGrey[500] }} />,
            tooltip: "Delete",
            onClick: (event, rowData) => {
              setDeleteConfirmOpen(true);
              //setActiveSubmission(rowData);
            },
            //disabled: rowData.tableData.id !== selectedRow,
            disabled: rowData.id !== props.selected?.id,
          }),
        ]}
      />
      <ConfirmDialog
        title="Are you sure?"
        open={submitConfirmOpen}
        setOpen={setSubmitConfirmOpen}
        onConfirm={onSubmitConfirmHandler}
      >
        This action can't be reverted!
        <p>
          It will <u>SUBMIT</u> submission{" "}
          <b> {props.selected && props.selected.name} </b> with all their claims
          and files.
        </p>
        <p>
          Please click{" "}
          <u>
            <b>Yes</b>
          </u>{" "}
          if you want to proceed.
        </p>
      </ConfirmDialog>
      <ConfirmDialog
        title="Are you sure?"
        open={deleteConfirmOpen}
        setOpen={setDeleteConfirmOpen}
        onConfirm={onDeleteConfirmHandler}
      >
        This action can't be reverted!
        <p>
          It will <u>DELETE</u> submission{" "}
          <b> {props.selected && props.selected.name} </b> with all their claims
          and files.
        </p>
        <p>
          Please click{" "}
          <u>
            <b>Yes</b>
          </u>{" "}
          if you want to proceed.
        </p>
      </ConfirmDialog>
    </React.Fragment>
  );
};

export default UnfinishedSubmissionsTable;
