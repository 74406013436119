import { submissionConstants } from "../_constants";

const initialState = {
  hsSubmission: {},
  hsLoading: false,
  hsError: null,
  hslSubmissionList: [],
  hslLoading: false,
  hslError: null,
  hsFirstSubmissionId: null,
  usSubmissions: [],
  usLoading: false,
  usError: null,
  usPostError: null,
  usPutError: null,
  usDeleteError: null,
  usSubmitError: null,
  usSelectedSubmission: {},
};

export function submissions(state = initialState, action) {
  switch (action.type) {
    case submissionConstants.SUBMISSION_HISTORY_GET_LIST_REQUEST:
      return {
        ...state,
        hslLoading: true,
        hsFirstSubmissionId: null,
      };
    case submissionConstants.SUBMISSION_HISTORY_GET_LIST_SUCCESS:
      return {
        ...state,
        hslSubmissionList: action.submissions,
        hslLoading: false,
        hslError: null,
        hsFirstSubmissionId:
          action.submissions.length > 0 ? action.submissions[0].id : null,
      };
    case submissionConstants.SUBMISSION_HISTORY_GET_LIST_FAILURE:
      return {
        ...state,
        hslError: action.error,
        hslLoading: false,
        hsFirstSubmissionId: null,
      };
    case submissionConstants.SUBMISSION_HISTORY_GET_BYID_REQUEST:
      return {
        ...state,
        hsLoading: true,
        hsFirstSubmissionId: null,
      };
    case submissionConstants.SUBMISSION_HISTORY_GET_BYID_SUCCESS:
      return {
        ...state,
        hsSubmission: action.submission,
        hsLoading: false,
        hsFirstSubmissionId: null,
        hsError: null,
      };
    case submissionConstants.SUBMISSION_HISTORY_GET_BYID_FAILURE:
      return {
        ...state,
        hsError: action.error,
        hsLoading: false,
        hsFirstSubmissionId: null,
      };
    case submissionConstants.GET_UNSUBMITTED_REQUEST:
      return { ...state, usLoading: true };
    case submissionConstants.GET_UNSUBMITTED_SUCCESS:
      return {
        ...state,
        usSubmissions: action.submissions,
        usLoading: false,
        usError: null,
      };
    case submissionConstants.GET_UNSUBMITTED_FAILURE:
      return {
        ...state,
        usError: action.error,
        usLoading: false,
      };
    case submissionConstants.POST_UNSUBMITTED_REQUEST:
      return { ...state, usPostError: null, usLoading: true };
    case submissionConstants.POST_UNSUBMITTED_FAILURE:
      return { ...state, usPostError: action.error, usLoading: false };
    case submissionConstants.PUT_UNSUBMITTED_REQUEST:
      return { ...state, usPutError: null, usLoading: true };
    case submissionConstants.PUT_UNSUBMITTED_FAILURE:
      return { ...state, usPutError: action.error, usLoading: false };
    case submissionConstants.DELETE_UNSUBMITTED_REQUEST:
      return { ...state, usDeleteError: null, usLoading: true };
    case submissionConstants.DELETE_UNSUBMITTED_SUCCESS:
      return {
        ...state,
        usDeleteError: null,
        usLoading: false,
        usSelectedSubmission: {},
      };
    case submissionConstants.DELETE_UNSUBMITTED_FAILURE:
      return { ...state, usDeleteError: action.error, usLoading: false };
    case submissionConstants.SUBMIT_UNSUBMITTED_REQUEST:
      return { ...state, usSubmitError: null, usLoading: true };
    case submissionConstants.SUBMIT_UNSUBMITTED_SUCCESS:
      return {
        ...state,
        usSubmitError: null,
        usLoading: false,
        usSelectedSubmission: {},
      };
    case submissionConstants.SUBMIT_UNSUBMITTED_FAILURE:
      return { ...state, usSubmitError: action.error, usLoading: false };
    case submissionConstants.UPDATE_SELECTED_UNSUBMITTED_SUBMISSION:
      return {
        ...state,
        usSelectedSubmission: action.submission,
      };
    default:
      return state;
  }
}
