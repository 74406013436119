import { contactConstants } from "../_constants";
import { contactService } from "../_services";

export const contactActions = {
    getByServicePartnerId,
};

function getByServicePartnerId(id) {
    return (dispatch) => {
        dispatch(request());

        contactService.getByServicePartnerId(id).then(
            (items) => dispatch(success(items)),
            (error) =>
                dispatch(failure("ERROR: Failed to fetch account contacts."))
        );
    };

    function request() {
        return { type: contactConstants.GET_ALL_REQUEST };
    }
    function success(items) {
        return { type: contactConstants.GET_ALL_SUCCESS, items };
    }
    function failure(error) {
        return { type: contactConstants.GET_ALL_FAILURE, error };
    }
}
