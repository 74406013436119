import { fileConstants, submissionConstants } from "../_constants";

const initialState = {
  items: [],
  loading: false,
  error: null,
};

export function files(state = initialState, action) {
  switch (action.type) {
    case fileConstants.GET_ALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case fileConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        items: action.items,
        loading: false,
      };
    case fileConstants.GET_ALL_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

const initialStateUpload = {
  uploading: false,
  error: null,
};

export function upload(state = initialStateUpload, action) {
  switch (action.type) {
    case fileConstants.SUBMISSION_DOCUMENT_UPLOAD_REQUEST:
      return {
        ...state,
        uploading: true,
      };
    case fileConstants.SUBMISSION_DOCUMENT_UPLOAD_SUCCESS:
      return {
        ...state,
        uploading: false,
      };
    case fileConstants.SUBMISSION_DOCUMENT_UPLOAD_FAILURE:
      return {
        ...state,
        error: action.error,
        uploading: false,
      };
    default:
      return state;
  }
}

const initialStateSubmission = {
  items: [],
  loading: false,
  error: null,
  deleteError: null,
};

export function documents(state = initialStateSubmission, action) {
  switch (action.type) {
    case fileConstants.GET_ALL_SUBMISSION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case fileConstants.GET_ALL_SUBMISSION_SUCCESS:
      return {
        ...state,
        items: action.items,
        loading: false,
      };
    case fileConstants.GET_ALL_SUBMISSION_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case submissionConstants.DELETE_UNSUBMITTED_SUCCESS:
    case submissionConstants.SUBMIT_UNSUBMITTED_SUCCESS:
      return {
        ...state,
        items: [],
      };
    case fileConstants.SUBMISSION_DOCUMENT_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case fileConstants.SUBMISSION_DOCUMENT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case fileConstants.SUBMISSION_DOCUMENT_DELETE_FAILURE:
      return {
        ...state,
        deleteError: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

// const deleteDocumentInitialState = {
//   deleting: false,
//   error: null,
// };

// export function deleteDocuments(state = deleteDocumentInitialState, action) {
//   switch (action.type) {
//     case fileConstants.SUBMISSION_DOCUMENT_DELETE_REQUEST:
//       return {
//         ...state,
//         deleting: true,
//       };
//     case fileConstants.SUBMISSION_DOCUMENT_DELETE_SUCCESS:
//       return {
//         ...state,
//         deleting: false,
//       };
//     case fileConstants.SUBMISSION_DOCUMENT_DELETE_FAILURE:
//       return {
//         ...state,
//         error: action.error,
//         deleting: false,
//       };
//     default:
//       return state;
//   }
// }
