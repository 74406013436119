import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  FormRoot: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "29%",
      color: theme.palette.text.secondary,
    },
  },
}));

const CompanyInformation = (props) => {
  const classes = useStyles();
  const { account } = props;
  return (
    <React.Fragment>
      {props.isLoading && (
        <CircularProgress
          size={20}
          style={{ position: "absolute", zIndex: "999999" }}
        />
      )}
      <br />
      <form className={classes.FormRoot} noValidate autoComplete="off">
        <div>
          <TextField
            InputProps={{
              readOnly: true,
            }}
            id="standard-disabled"
            label="Name"
            value={account.name || ""}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            InputProps={{
              readOnly: true,
            }}
            id="standard-disabled"
            label="Street"
            value={account.street || ""}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            InputProps={{
              readOnly: true,
            }}
            id="standard-disabled"
            label="City"
            value={account.city || ""}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            InputProps={{
              readOnly: true,
            }}
            id="standard-disabled"
            label="Country"
            value={account.country || ""}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            InputProps={{
              readOnly: true,
            }}
            id="standard-disabled"
            label="Province"
            value={account.province || ""}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            InputProps={{
              readOnly: true,
            }}
            id="standard-disabled"
            label="Postal Code"
            value={account.postalCode || ""}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            InputProps={{
              readOnly: true,
            }}
            id="standard-disabled"
            label="Main Phone"
            value={account.mainPhone || ""}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            InputProps={{
              readOnly: true,
            }}
            id="standard-disabled"
            label="Other Phone"
            value={account.otherPhone || ""}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            InputProps={{
              readOnly: true,
            }}
            id="standard-disabled"
            label="Fax"
            value={account.fax || ""}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </form>
    </React.Fragment>
  );
};

export default CompanyInformation;
