import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Box from "@material-ui/core/Box";
import { useDispatch, useSelector } from "react-redux";
import { claimActions } from "../../../_actions";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    overflowY: "hidden",
    overflowX: "hidden",
  },
}));

let initialValues = {
  id: 0,
  submissionId: null,
  documentNumber: "",
  pickupDate: null,
  deliveryDate: null,
  quantity: "",
  weight: "",
  serviceTypeId: null,
  materialCategoryId: null,
  containerTypeId: null,
  bulbTypeId: null,
  unitId: null,
  collectionSiteId: null,
  transporterId: null,
  processorId: null,
};

const ClaimForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const user = JSON.parse(localStorage.getItem("user"));
  // const servicePartnerId =
  //   user && user.servicePartner ? user.servicePartner : "";

  const accounts = useSelector((state) => state.accounts);
  const materialCategories = useSelector((state) => state.materialCategories);
  const serviceTypes = useSelector((state) => state.serviceTypes);
  const containerTypes = useSelector((state) => state.containerTypes);
  const bulbTypes = useSelector((state) => state.bulbTypes);
  const units = useSelector((state) => state.units);
  const transporters = useSelector((state) => state.transporters);
  const processors = useSelector((state) => state.processors);
  const collectionSites = useSelector((state) => state.collectionSites);

  useEffect(() => {
    if (props.selectedClaim) {
      handleReset();

      let sc = props.selectedClaim;

      let pdate = sc.pickupDate.split("/");
      let ddate = sc.deliveryDate.split("/");

      let pickupDate = new Date(
        Number(pdate[2]),
        Number(pdate[0] - 1),
        Number(pdate[1])
      );
      let deliveryDate = new Date(
        Number(ddate[2]),
        Number(ddate[0] - 1),
        Number(ddate[1])
      );

      initialValues = {
        id: sc.id,
        submissionId: props.selectedSubmissionId,
        documentNumber: sc.documentNumber,
        pickupDate: pickupDate,
        deliveryDate: deliveryDate,
        quantity: sc.quantity,
        weight: sc.weight,
        serviceTypeId: serviceTypes.list.find(
          (x) => x.code == sc.serviceTypeId
        ),
        materialCategoryId: materialCategories.list.find(
          (x) => x.code == sc.materialCategoryId
        ),
        containerTypeId: containerTypes.list.find(
          (x) => x.code == sc.containerTypeId
        ),
        bulbTypeId: bulbTypes.list.find(
          (x) => x.code == sc.bulbTypeId
        ),
        unitId: units.list.find((x) => x.code == sc.unitId),
        collectionSiteId: collectionSites.items.find(
          (x) => x.serviceSiteId == sc.collectionSiteId
        ),
        transporterId: transporters.items.find(
          (x) => x.servicePartnerId == sc.transporterId
        ),
        processorId: processors.items.find(
          (x) => x.servicePartnerId == sc.processorId
        ),
      };
      filterMaterialCategoryDropdown(sc.serviceTypeId);
      
      if(initialValues.bulbTypeId){
        setShowBulbTypeControl(true);
        setDu([860910001]);
        setDc([100000005]);
      }
    } else {
      initialValues = {
        id: "",
        submissionId: props.selectedSubmissionId,
        documentNumber: "",
        pickupDate: null,
        deliveryDate: null,
        quantity: "",
        weight: "",
        serviceTypeId: null,
        materialCategoryId: null,
        containerTypeId: null,
        bulbTypeId: null,
        unitId: null,
        collectionSiteId: null,
        transporterId: null,
        processorId: null,
      };

      let dis = [...materialCategories.list].map((a) => a.code);
      //setDmc(dis);

      setDu([860910001]);
      setDc([100000005]);
    }

    let tempDst = [...serviceTypes.list].map((a) => a.code);
    setDst(tempDst);

    filterServiceTypesDropdown(accounts.account);

    setValues(initialValues);
  }, []);

  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [dst, setDst] = useState([]);
  const [du, setDu] = useState([]);
  const [dc, setDc] = useState([]);
  const [dmc, setDmc] = useState([]);
  const [showWeightColor, setShowWeightColor] = useState(false);
  const [showBulbTypeControl, setShowBulbTypeControl] = useState(false);

  const handleDateChange = (date, name) => {
    setValues({
      ...values,
      [name]: date,
    });
    validate({ [name]: date });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
    // if (
    //   name === "weight" ||
    //   name === "quantity" ||
    //   name === "containerTypeId"
    // ) {
    //   validateWeightValue();
    // }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("serviceTypeId" in fieldValues) {
      temp.serviceTypeId = fieldValues.serviceTypeId
        ? ""
        : "Service Type is required";
    }

    if ("documentNumber" in fieldValues) {
      temp.documentNumber = fieldValues.documentNumber
        ? ""
        : "Document number is required";
    }

    if ("pickupDate" in fieldValues) {
      temp.pickupDate =
        fieldValues.pickupDate == "Invalid Date" ||
        fieldValues.pickupDate == "" ||
        fieldValues.pickupDate === null
          ? "Pickup Date is required"
          : "";
    }

    if ("deliveryDate" in fieldValues) {
      temp.deliveryDate =
        fieldValues.deliveryDate == "Invalid Date" ||
        fieldValues.deliveryDate == "" ||
        fieldValues.deliveryDate === null
          ? "Delivery Date is required"
          : "";

      if (temp.deliveryDate == "") {
        if (values.pickupDate) {
          let d1 = values.pickupDate;
          let d2 = fieldValues.deliveryDate;

          d1.setHours(0, 0, 0, 0);
          d2.setHours(0, 0, 0, 0);

          if (d1.getTime() > d2.getTime()) {
            temp.deliveryDate = "Delivery Date cannot be before Pickup Date";
          }
        }
      }
    }

    if ("collectionSiteId" in fieldValues) {
      temp.collectionSiteId = fieldValues.collectionSiteId
        ? ""
        : "Collection Site is required";
    }

    if ("transporterId" in fieldValues) {
      temp.transporterId = fieldValues.transporterId
        ? ""
        : "Transporter is required";
    }

    if ("processorId" in fieldValues) {
      temp.processorId = fieldValues.processorId ? "" : "Processor is required";
    }

    if ("materialCategoryId" in fieldValues) {
      temp.materialCategoryId = fieldValues.materialCategoryId
        ? ""
        : "Material Category is required";
    }

    if ("containerTypeId" in fieldValues) {
      temp.containerTypeId = fieldValues.containerTypeId
        ? ""
        : "Container Type is required";
    }

    if ("unitId" in fieldValues) {
      temp.unitId = fieldValues.unitId ? "" : "Unit is required";

      if(showBulbTypeControl && fieldValues.unitId){
        if(fieldValues.unitId.code != 860910001){
          temp.unitId = "Unit should be set to 'piece' for this Material Category and Service Type combination";
        }
      }
    }

    if ("quantity" in fieldValues) {
      temp.quantity =
        fieldValues.quantity && fieldValues.quantity > 0
          ? ""
          : "Quantity is required";
    }

    if ("weight" in fieldValues) {
      temp.weight = fieldValues.weight && fieldValues.weight > 0 ? "" : "Weight is required";
      if(showBulbTypeControl) temp.weight = "";
    }

    if ("bulbTypeId" in fieldValues) {
      if(showBulbTypeControl){
        temp.bulbTypeId = fieldValues.bulbTypeId ? "" : "Bulb Type is required";
      }
    }

    setErrors({ ...temp });


    if (fieldValues == values) {
      return Object.values(temp).every((x) => x == "");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      let pickupDate =
        values.pickupDate.getFullYear() +
        "-" +
        (values.pickupDate.getMonth() + 1) +
        "-" +
        values.pickupDate.getDate();

      let deliveryDate =
        values.deliveryDate.getFullYear() +
        "-" +
        (values.deliveryDate.getMonth() + 1) +
        "-" +
        values.deliveryDate.getDate();

      let finalValues = {
        DocumentNumber: values.documentNumber,
        PickupDate: pickupDate,
        DeliveryDate: deliveryDate,
        Quantity: values.quantity,
        Weight: values.weight,
        ServiceTypeId: values.serviceTypeId.code.toString(),
        MaterialCategoryId: values.materialCategoryId.code.toString(),
        ContainerTypeId: values.containerTypeId.code.toString(),
        BulbTypeId: showBulbTypeControl ? values.bulbTypeId?.code?.toString() : null,
        UnitId: values.unitId.code.toString(),
        CollectionSiteId: values.collectionSiteId.serviceSiteId,
        TransporterId: values.transporterId.servicePartnerId,
        ProcessorId: values.processorId.servicePartnerId,
      };

      if (props.selectedClaim) {
        finalValues.Id = values.id;
        console.log(finalValues);
        dispatch(
          claimActions.updateClaim(finalValues, props.selectedSubmissionId)
        );
      } else {
        finalValues.SubmissionId = values.submissionId;
        dispatch(
          claimActions.postClaim(finalValues, props.selectedSubmissionId)
        );
      }

      props.handleClose();
      window.scrollTo(0, 0);
      // setTimeout(() => {
      //   resolve();
      //   dispatch(claimActions.postClaim(newData, selectedSubmissionId));
      // }, 600);
    } else {
      console.log("form is invalid");
    }
  };

  const handleReset = () => {
    setValues({
      id: 0,
      submissionId: null,
      documentNumber: "",
      pickupDate: null,
      deliveryDate: null,
      quantity: "",
      weight: "",
      serviceTypeId: null,
      materialCategoryId: null,
      containerTypeId: null,
      bulbTypeId: null,
      unitId: null,
      collectionSiteId: null,
      transporterId: null,
      processorId: null,
    });
    setErrors({});
    setShowBulbTypeControl(false);
  };
  const handleAutocompleteChange = (e, newValue, name) => {
    e.preventDefault();
    setValues({
      ...values,
      [name]: newValue,
    });
    
    if (name == "serviceTypeId") {
      if(newValue.code == 860910002 && values.materialCategoryId?.code == 100000003){
        setShowBulbTypeControl(true);
        setValues({
          ...values,
          ["unitId"]: units.list.find((x) => x.code == 860910001),
          ["weight"]: "0",
          ["containerTypeId"]: containerTypes.list.find((x) => x.code == 100000005),
          [name]: newValue,
        });

        setDisabledContainerAndUnit(true);
      }
      else
      {
        setValues({
          ...values,
          ["unitId"]: null,
          ["weight"]: "",
          ["containerTypeId"]: null,
          [name]: newValue,
        });
        setShowBulbTypeControl(false);
        setDisabledContainerAndUnit(false);
      }
    }

    if (name == "materialCategoryId") {
      if(newValue.code == 100000003 && values.serviceTypeId?.code == 860910002){
        setShowBulbTypeControl(true);
        setValues({
          ...values,
          ["unitId"]: units.list.find((x) => x.code == 860910001),
          ["weight"]: "0",
          ["containerTypeId"]: containerTypes.list.find((x) => x.code == 100000005),
          [name]: newValue,
        });
        setDisabledContainerAndUnit(true);
      }
      else
      {
        setValues({
          ...values,
          ["unitId"]: null,
          ["weight"]: "",
          ["containerTypeId"]: null,
          [name]: newValue,
        });
        setShowBulbTypeControl(false);
        setDisabledContainerAndUnit(false);
      }
    } 

    validate({ [name]: newValue });

    if (name == "serviceTypeId") {
      filterMaterialCategoryDropdown(newValue.code);
    }

    // if (name === "materialCategoryId" || name === "containerTypeId") {
    //   validateWeightValue();
    // }
  };

  const filterMaterialCategoryDropdown = (st) => {
    let disabled = [];
    //if (st == 860910001 || st == 860910002 || st == 100000017) {
    //  disabled.push(860910001);
    //  disabled.push(860910002);
    //  disabled.push(860910003);
    //  disabled.push(860910004);
    //} else if (st == 860910003) {
    //} else {
    //  disabled.push(860910000);
    //}

    if (st == 860910004 || st == 860910000 || st == 100000000 || st == 860910003 || st == 100000017 || st == 860910001) {
      disabled.push(735090002);
      disabled.push(735090001);
    }

    setDmc([...disabled]);
    // {code: 860910000, value: "Paints & Coatings"}
    // {code: 860910001, value: "Aerosols"}
    // {code: 860910002, value: "Pesticides"}
    // {code: 860910003, value: "Misc. Organics"}
    // {code: 860910004, value: "Fertilizers"}
  };

  const setDisabledContainerAndUnit = (isProcessing) => {
    if(isProcessing){
      setDu([860910000]);
      setDc([...containerTypes.list.filter(x => x.code != 100000005).map((a) => a.code)]);
    }
    else
    {
      setDu([860910001]);
      setDc([...containerTypes.list.filter(x => x.code == 100000005).map((a) => a.code)]);
    }
  };

  const filterServiceTypesDropdown = (account) => {
    let disabled = [];

    disabled.push(860910000);
    disabled.push(860910001);
    disabled.push(860910002);
    disabled.push(860910003);
    disabled.push(100000017);
    disabled.push(100000000);
    disabled.push(860910004);

    if (account.servicePartnerTypeMunicipality) {
      disabled.splice(disabled.indexOf(860910000), 1);
      disabled.splice(disabled.indexOf(860910003), 1);
      disabled.splice(disabled.indexOf(100000017), 1);
    }

    if (account.servicePartnerTypeProcessor) {
      disabled.splice(disabled.indexOf(860910002), 1);
    }

    if (account.servicePartnerTypeTransporter) {
      disabled.splice(disabled.indexOf(860910001), 1);
      disabled.splice(disabled.indexOf(100000000), 1);
    }

    setDst([...disabled]);
    // {code: 860910001, value: "Transportation"}
    // {code: 860910002, value: "Processing"}
    // {code: 860910000, value: "Commingled Material"}
    // {code: 860910003, value: "Event"}
    // {code: 100000017, value: "ReUse"}
    // {code: 100000000, value: "Courier"}
  };

  useEffect(() => {
    validateWeightValue();
  }, [values]);

  const validateWeightValue = () => {
    setShowWeightColor(false);

    if (
      values.weight === "" ||
      values.quantity === "" ||
      !values.containerTypeId ||
      !values.materialCategoryId
    ) {
      return;
    }

    let options = [
      {
        mcCode: 860910001,
        ctCode: 860910001,
        xRange: 34,
        yRange: 70,
      }, //Aerosols - 205L Drum
      {
        mcCode: 860910004,
        ctCode: 860910001,
        xRange: 53,
        yRange: 106,
      }, //Fertilizers - 205L Drum
      {
        mcCode: 860910003,
        ctCode: 860910004,
        xRange: 1000,
        yRange: 1200,
      }, //Misc. Organics - 1350L Cage A
      {
        mcCode: 860910003,
        ctCode: 860910005,
        xRange: 1000,
        yRange: 1200,
      }, //"Misc. Organics - 1550L Cage B
      {
        mcCode: 860910003,
        ctCode: 860910001,
        xRange: 50,
        yRange: 105,
      }, //Misc. Organics - 205L Drum
      {
        mcCode: 860910003,
        ctCode: 860910003,
        xRange: 6,
        yRange: 20,
      }, //Misc. Organics - 20L Pail
      {
        mcCode: 860910003,
        ctCode: 860910002,
        xRange: 360,
        yRange: 480,
      }, //Misc. Organics - Gaylord
      {
        mcCode: 860910000,
        ctCode: 863910004,
        xRange: 0.01,
        yRange: 1000,
      }, //Paints & Coatings - Paint Can
      {
        mcCode: 860910000,
        ctCode: 860910005,
        xRange: 1000,
        yRange: 1300,
      }, //Paints & Coatings - 1550L Cage B
      {
        mcCode: 860910000,
        ctCode: 860910001,
        xRange: 50,
        yRange: 130,
      }, //Paints & Coatings - 205L Drum

      {
        mcCode: 860910000,
        ctCode: 860910003,
        xRange: 5,
        yRange: 27,
      }, //Paints & Coatings - 20L Pail
      {
        mcCode: 860910000,
        ctCode: 860910002,
        xRange: 220,
        yRange: 560,
      }, //Paints & Coatings - Gaylord
      {
        mcCode: 860910000,
        ctCode: 279640000,
        xRange: 120,
        yRange: 360,
      }, //Paints & Coatings - Tubskid
      {
        mcCode: 860910000,
        ctCode: 860910004,
        xRange: 900,
        yRange: 1200,
      }, //Paints & Coatings - 1350L Cage A
      {
        mcCode: 860910002,
        ctCode: 860910001,
        xRange: 40,
        yRange: 90,
      }, //Pesticides - 205L Drum
      {
        mcCode: 860910002,
        ctCode: 860910003,
        xRange: 5,
        yRange: 20,
      }, //Pesticides - 20L Pail
    ];

    let found = options.find(
      (x) =>
        x.mcCode === values.materialCategoryId.code &&
        x.ctCode === values.containerTypeId.code
    );

    if (found) {
      if (
        values.weight < values.quantity * found.xRange ||
        values.weight > values.quantity * found.yRange
      ) {
        setShowWeightColor(true);
      }
    }
    // {code: 860910000, value: "Paints & Coatings"}
    // {code: 860910001, value: "Aerosols"}
    // {code: 860910002, value: "Pesticides"}
    // {code: 860910003, value: "Misc. Organics"}
    // {code: 860910004, value: "Fertilizers"}

    // container types
    //{code: 863910004, value: "Paint Can"}
    //{code: 860910001, value: "205L Drum"}
    //{code: 860910002, value: "Gaylord"}
    //{code: 860910003, value: "20L Pail"}
    //{code: 860910004, value: "1350L Cage A"}
    //{code: 860910005, value: "1550L Cage B"}
    //{code: 279640000, value: "Tubskid"}
  };

  return (
    <React.Fragment>
      {materialCategories.loading ||
      containerTypes.loading ||
      bulbTypes.loading ||
      serviceTypes.loading ||
      units.loading ||
      processors.loading ||
      transporters.loading ||
      collectionSites.loading ? (
        <LinearProgress
          color="primary"
          style={{ margin: "0px 0px 25px 0px" }}
        />
      ) : (
        <Box component="div" visibility="visible" style={{ height: 25 }} />
      )}
      <form onSubmit={handleSubmit} autoComplete="off" data-cy="claimForm">
        <Grid container style={{ width: 800 }} padding={2} spacing={2}>
          <Grid item xs={6}>
          <Autocomplete
              id="materialCategoryId"
              name="materialCategoryId"
              value={values.materialCategoryId}
              options={materialCategories.list}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange(
                    event,
                    newValue,
                    "materialCategoryId"
                  );
                }
              }}
              //getOptionSelected={(option, value) => option.code == value.code}
              getOptionDisabled={(option) =>
                !!dmc.find((element) => element == option.code)
              }
              getOptionLabel={(option) => option.value}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Material Category*"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...(errors.materialCategoryId && {
                    error: true,
                    helperText: errors.materialCategoryId,
                  })}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="serviceTypeId"
              name="serviceTypeId"
              value={values.serviceTypeId}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange(event, newValue, "serviceTypeId");
                }
              }}
              options={serviceTypes.list}
              getOptionSelected={(option, value) => option.code == value.code}
              getOptionDisabled={(option) => !!dst.find((element) => element == option.code)}
              getOptionLabel={(option) => option.value}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Service Type*"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...(errors.serviceTypeId && {
                    error: true,
                    helperText: errors.serviceTypeId,
                  })}
                />
              )}
            />
          </Grid>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item xs={6}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="pickupDate"
                name="pickupDate"
                label="Pickup Date*"
                value={values.pickupDate}
                onChange={(date) => {
                  if (date) {
                    handleDateChange(date, "pickupDate");
                  }
                }}
                {...(errors.pickupDate && {
                  error: true,
                  helperText: errors.pickupDate,
                })}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                style={{ width: 300 }}
                autoOk={true}
              />
            </Grid>
            <Grid item xs={6}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="deliveryDate"
                name="deliveryDate"
                label="Delivery Date*"
                value={values.deliveryDate}
                onChange={(date) => {
                  if (date) {
                    handleDateChange(date, "deliveryDate");
                  }
                }}
                {...(errors.deliveryDate && {
                  error: true,
                  helperText: errors.deliveryDate,
                })}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                style={{ width: 300 }}
                autoOk={true}
              />
            </Grid>
          </MuiPickersUtilsProvider>
          <Grid item xs={6}>
            <Autocomplete
              id="collectionSiteId"
              name="collectionSiteId"
              value={values.collectionSiteId}
              options={collectionSites.items}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange(event, newValue, "collectionSiteId");
                }
              }}
              //getOptionSelected={(option, value) =>
              //   option.serviceSiteId == value.serviceSiteId
              // }
              getOptionLabel={(option) => option.name}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Collection Site*"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...(errors.collectionSiteId && {
                    error: true,
                    helperText: errors.collectionSiteId,
                  })}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="transporterId"
              name="transporterId"
              value={values.transporterId}
              options={transporters.items}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange(event, newValue, "transporterId");
                }
              }}
              //getOptionSelected={(option, value) =>
              //  option.servicePartnerId == value.servicePartnerId
              //}
              getOptionLabel={(option) => option.name}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Transporter*"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...(errors.transporterId && {
                    error: true,
                    helperText: errors.transporterId,
                  })}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="processorId"
              name="processorId"
              value={values.processorId}
              options={processors.items}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange(event, newValue, "processorId");
                }
              }}
              //getOptionSelected={(option, value) =>
              //  option.servicePartnerId == value.servicePartnerId
              //}
              getOptionLabel={(option) => option.name}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Processor*"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...(errors.processorId && {
                    error: true,
                    helperText: errors.processorId,
                  })}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
          <TextField
              id="documentNumber"
              name="documentNumber"
              label="Document Number*"
              value={values.documentNumber}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: 300 }}
              onChange={handleInputChange}
              {...(errors.documentNumber && {
                error: true,
                helperText: errors.documentNumber,
              })}
            />
          </Grid>
          <Grid item xs={6}>
          <Autocomplete
              id="containerTypeId"
              name="containerTypeId"
              value={values.containerTypeId}
              options={containerTypes.list}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange(event, newValue, "containerTypeId");
                }
              }}
              //getOptionSelected={(option, value) => option.code == value.code}
              getOptionLabel={(option) => option.value}
              getOptionDisabled={(option) => !!dc.find((element) => element == option.code)}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Container Type*"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...(errors.containerTypeId && {
                    error: true,
                    helperText: errors.containerTypeId,
                  })}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="unitId"
              name="unitId"
              value={values.unitId}
              options={units.list}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange(event, newValue, "unitId");
                }
              }}
              //getOptionSelected={(option, value) => option.code === value.code}
              getOptionLabel={(option) => option.value}
              getOptionDisabled={(option) => !!du.find((element) => element == option.code)}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Unit*"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...(errors.unitId && {
                    error: true,
                    helperText: errors.unitId,
                  })}
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="quantity"
              name="quantity"
              value={values.quantity}
              label="Quantity*"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              onChange={handleInputChange}
              {...(errors.quantity && {
                error: true,
                helperText: errors.quantity,
              })}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled={showBulbTypeControl}
              id="weight"
              name="weight"
              value={values.weight}
              label="Weight*"
              type="number"
              step={0.5}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              onChange={handleInputChange}
              {...(errors.weight && {
                error: true,
                helperText: errors.weight,
              })}
              style={{ backgroundColor: showWeightColor ? "#ffe0b2" : "" }}
            />
          </Grid>
          <Grid item xs={6}>
          {showBulbTypeControl && <Autocomplete
              id="bulbTypeId"
              name="bulbTypeId"
              value={values.bulbTypeId}
              options={bulbTypes.list}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange(event, newValue, "bulbTypeId");
                }
              }}
              //getOptionSelected={(option, value) => option.code == value.code}
              getOptionLabel={(option) => option.value}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Bulb Type*"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...(errors.bulbTypeId && {
                    error: true,
                    helperText: errors.bulbTypeId,
                  })}
                />
              )}
            />}
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              alignItems="center"
              justify="center"
              padding={2}
              spacing={2}
            >
              <Box m={2} pt={3}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  data-cy="saveClaim"
                >
                  Save
                </Button>
              </Box>
              <Box m={2} pt={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  padding={2}
                  spacing={2}
                  type="reset"
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
};

export default ClaimForm;
