import React, { useState, useEffect, useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FilesTable from "./FilesTable";
import UnfinishedSubmissionsTable from "./UnfinishedSubmissionsTable";
import ClaimsEditableTable from "./ClaimsEditableTable";
import Button from "@material-ui/core/Button";
import { Container, Hidden } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import SaveIcon from "@material-ui/icons/Save";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import PublishIcon from "@material-ui/icons/Publish";
import { useDispatch, useSelector } from "react-redux";
import {
  claimActions,
  submissionActions,
  accountActions,
  locationActions,
  fileActions,
} from "../../../_actions";
import { toast } from "react-toastify";
import ClaimDialog from "./ClaimDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    overflowY: "hidden",
    overflowX: "hidden",
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    border: "1px #EEEEEE solid",
    background: "#FFFFFF",
    minHeight: 250,
  },
  button: {
    margin: theme.spacing(1),
    position: "absolute",
    zIndex: "999999",
    margin: "15px 2px 2px 50px",
  },
  ClaimsGridContainer: {
    //height: "100vh",
    overflowX: "auto",
    height: "100%",
    width: "100%",
  },
}));

const Submissions = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const servicePartnerId =
    user && user.servicePartner ? user.servicePartner : "";

  const [open, setOpen] = useState(false);

  const [selectedClaim, setSelectedClaim] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //#region SELECTORS
  const {
    usSubmissions,
    usLoading,
    usError,
    usPostError,
    usPutError,
    usDeleteError,
    usSelectedSubmission,
  } = useSelector((state) => state.submissions);
  const documents = useSelector((state) => state.documents);
  const fileInput = useRef(null);
  const claimsGridRef = useRef();
  // const materialCategories = useSelector((state) => state.materialCategories);
  // const serviceTypes = useSelector((state) => state.serviceTypes);
  // const containerTypes = useSelector((state) => state.containerTypes);
  // const units = useSelector((state) => state.units);
  // const transporters = useSelector((state) => state.transporters);
  // const processors = useSelector((state) => state.processors);
  // const collectionSites = useSelector((state) => state.collectionSites);
  const claims = useSelector((state) => state.submissionClaims);
  const { uploading } = useSelector((state) => state.upload);
  const selectedSubmissionId = usSelectedSubmission?.id;

  //#endregion

  //#region SUBMISSION TABLE EVENTS
  const usTableRowDelete = () => {
    if (selectedSubmissionId) {
      dispatch(
        submissionActions.deleteUnsubmittedSubmission(selectedSubmissionId)
      );
    }
  };
  const usTableOnRowClick = (selected) => {
    dispatch(submissionActions.updateSelectedUnsubmittedSubmission(selected));

    dispatch(fileActions.getDocumentsBySubmissionId(selected?.id));

    dispatch(claimActions.getClaimsBySubmissionForSubmission(selected?.id));

    dispatch(claimActions.getMaterialCategories());
    dispatch(claimActions.getContainerTypes());
    dispatch(claimActions.getBulbTypes());
    dispatch(claimActions.getServiceTypes());
    dispatch(claimActions.getUnits());
    dispatch(accountActions.getTransporters());
    dispatch(accountActions.getProcessors());
    dispatch(accountActions.getById(servicePartnerId));
    dispatch(locationActions.getCollectionSites(servicePartnerId));
  };
  const usTableOnRowAdd = (rowData) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const submission = {
      name: rowData.name,
      servicePartnerId: user.servicePartner,
      contactId: user.contactId,
    };

    dispatch(submissionActions.postUnsubmittedSubmission(submission));
  };
  const usTableOnRowUpdate = (newData, oldData) => {
    if (newData.name === oldData.name) return;

    const submission = {
      id: newData.id,
      name: newData.name,
    };

    dispatch(submissionActions.putUnsubmittedSubmission(submission));
  };
  const usTableOnRowSubmit = () => {
    if (typeof documents.items == "undefined" || documents.items.length == 0) {
      toast.warning("Cannot submit without documents", {
        toastId: "submissions-submit-missing-documents",
      });
      return;
    }

    if (typeof claims.scClaims == "undefined" || claims.scClaims.length == 0) {
      toast.warning("Cannot submit without claims", {
        toastId: "submissions-submit-missing-claims",
      });
      return;
    }

    if (selectedSubmissionId) {
      dispatch(
        submissionActions.submitUnsubmittedSubmission(selectedSubmissionId)
      );
    } else {
      toast.warning("Submission is not selected", {
        toastId: "submissions-submit-missing-selected",
      });
    }
  };
  //#endregion

  //#region CLAIM TABLE EVENTS
  const claimTableRowCopy = (rowData) => {
    //claimsGridRef.current.scrollIntoView({ behavior: "smooth" });
    window.scrollTo(0, 0);
    return new Promise((resolve) => {
      resolve();
      if (rowData) {
        dispatch(claimActions.postClaim(rowData, selectedSubmissionId));
      }
    });
  };
  const claimTableRowAdd = () => {
    //claimsGridRef.current.scrollIntoView({ behavior: "smooth" });
    setSelectedClaim(null);
    setOpen(selectedSubmissionId ? true : false);
  };
  const claimTableRowUpdate = (rowData) => {
    //claimsGridRef.current.scrollIntoView({ behavior: "smooth" });
    setSelectedClaim(rowData);
    setOpen(selectedSubmissionId ? true : false);
  };
  const claimTableRowDelete = (oldData) => {
    window.scrollTo(0, 0);
    //claimsGridRef.current.scrollIntoView({ behavior: "smooth" });
    return new Promise((resolve) => {
      dispatch(claimActions.deleteClaim(oldData.id, selectedSubmissionId));
      resolve();
    });
  };
  const claimTableOnRowClick = (event, rowData) => {
    console.log(rowData);
  };
  //#endregion

  //#region FILES TABLE EVENTS
  const filesTableRowDelete = (oldData) => {
    return new Promise((resolve) => {
      dispatch(
        fileActions.deleteDocument(oldData.documentId, selectedSubmissionId)
      );
      resolve();
    });
  };
  const filesTableRowAdd = () => {
    if (selectedSubmissionId) {
      openFileUpload();
    }
  };
  const onChangeFileHandler = (event) => {
    const data = new FormData();
    data.append("submissionId", selectedSubmissionId);

    if (event.target.files.length > 0) {
      event.target.files.forEach((file) => {
        data.append("files", file);
      });
    }
    dispatch(fileActions.uploadDocument(data, selectedSubmissionId));
  };
  const openFileUpload = () => {
    fileInput.current.click();
  };
  //#endregion

  //#region EFFECTS
  useEffect(() => {
    dispatch(submissionActions.getUnsubmittedSubmissions());
  }, []);

  // Commented errors handling as they will be moved to actions
  //
  // useEffect(() => {
  //   console.log("Clear submission id");
  // }, usSubmissions);
  // useEffect(() => {
  //   if (usError) {
  //     toast.error(usError, {
  //       toastId: "submissions-fetch-error",
  //     });
  //   }
  // }, usError);

  // useEffect(() => {
  //   if (usPostError) {
  //     toast.error(usPostError, {
  //       toastId: "submissions-post-error",
  //     });
  //   }
  // }, usPostError);

  // useEffect(() => {
  //   if (usPutError) {
  //     toast.error(usPutError, {
  //       toastId: "submissions-put-error",
  //     });
  //   }
  // }, usPutError);

  // useEffect(() => {
  //   if (usDeleteError) {
  //     toast.error(usDeleteError, {
  //       toastId: "submissions-delete-error",
  //     });
  //   }
  // }, usDeleteError);
  //#endregion

  return (
    <div className={classes.root}>
      <Grid container padding={3} spacing={3}>
        <Grid item xs={12} sm={6}>
          <fieldset className={classes.paper} data-cy="submissionsContainer">
            <legend>SAVED UNFINISHED SUBMISSIONS</legend>
            <UnfinishedSubmissionsTable
              data={usSubmissions}
              rowDelete={usTableRowDelete}
              onRowClick={usTableOnRowClick}
              onRowAdd={usTableOnRowAdd}
              onRowUpdate={usTableOnRowUpdate}
              onRowSubmit={usTableOnRowSubmit}
              selected={usSelectedSubmission}
              paging={false}
              isLoading={usLoading}
            />
          </fieldset>
        </Grid>
        <Grid item xs={12} sm={6}>
          <fieldset className={classes.paper} data-cy="documentsContainer">
            <legend>SUBMISSION/SHIPPING DOCUMENTS</legend>
            <div>
              <input
                type="file"
                name="files"
                multiple
                onChange={onChangeFileHandler}
                ref={fileInput}
                style={{ display: "none" }}
              />
            </div>
            <FilesTable
              data={documents.items}
              rowDelete={filesTableRowDelete}
              rowAdd={filesTableRowAdd}
              paging={false}
              isLoading={documents.loading}
              isUploading={uploading}
            />
          </fieldset>
        </Grid>
        <Grid item xs={12}>
          <ClaimDialog
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            selectedSubmissionId={selectedSubmissionId}
            selectedClaim={selectedClaim}
          />
          <div className={classes.ClaimsGridContainer} ref={claimsGridRef}>
            <fieldset className={classes.paper} data-cy="claimsContainer">
              <legend>CLAIMS</legend>
              <ClaimsEditableTable
                claims={claims.scClaims}
                // mc={materialCategories.list}
                // ct={containerTypes.list}
                // st={serviceTypes.list}
                // units={units.list}
                // processors={processors.items}
                // transporters={transporters.items}
                // cs={collectionSites.items}
                rowAdd={claimTableRowAdd}
                rowUpdate={claimTableRowUpdate}
                rowDelete={claimTableRowDelete}
                rowCopy={claimTableRowCopy}
                onRowClick={claimTableOnRowClick}
                paging={false}
                isLoading={claims.scLoading}
              />
            </fieldset>
          </div>
          <br />
          <br />
        </Grid>
      </Grid>
    </div>
  );
};

export default Submissions;
