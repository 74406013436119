import { httpClient } from "../_helpers";

export const accountService = {
  getById,
  getProcessors,
  getTransporters,
};

function getById(id) {
  return httpClient
    .get(`/servicepartner/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function getProcessors() {
  return httpClient
    .get(`/servicepartner/processors`)
    .then((response) => {
      var arr = response.data;
      //   var obj = arr.reduce(function (acc, cur, i) {
      //     acc[cur.servicePartnerId] = cur.name;

      //     return acc;
      //   }, {});
      return arr;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function getTransporters() {
  return httpClient
    .get(`/servicepartner/transporters`)
    .then((response) => {
      var arr = response.data;
      // var obj = arr.reduce(function(acc, cur, i) {
      //     acc[cur.servicePartnerId] = cur.name;

      //     return acc;
      //   }, {});
      return arr;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
