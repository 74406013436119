import axios from "axios";

export const httpClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});


httpClient.interceptors.request.use(function (config) {
    const user = JSON.parse(localStorage.getItem("user"));
    config.headers.Authorization =
        user && user.token ? `Bearer ${user.token}` : "";
    return config;
});

httpClient.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    if(401 === error.response.status)
    {
        localStorage.removeItem('user');
        window.location.reload(true);
    }
    else
    {
        //eturn error;
        return Promise.reject(error);
    }
  });
