import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import BusinessIcon from "@material-ui/icons/Business";
import PhoneIcon from "@material-ui/icons/Phone";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import Divider from "@material-ui/core/Divider";
import { orange, blue, purple, red } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import { Autorenew } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2, 2, 8),
    overflowY: "hidden",
    overflowX: "hidden",
  },
  title: {
    textAlign: "center",
    fontSize: "1.3rem",
    color: "#393c4a",
    margin: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    border: "1px #EEEEEE solid",
    background: "#FFFFFF",
  },
  listStyle: {
    padding: theme.spacing(2),
  },
  listItemStyle: {
    padding: "2px",
  },
  lastItemStyle: {
    margin: theme.spacing(0, 0, 2),
    padding: "2px",
  },
  listHeader: {
    fontWeight: "bold",
  },
  bgSection: {
    padding: theme.spacing(2),
    backgroundColor: "#eb4a42",
    borderRadius: "4px",
  },
  centeredEl: {
    margin: "0 auto",
  },
  iconHeadOffice: {
    color: "#fff",
    fontSize: 45,
  },
  headOfficeSection: {
    color: "white",
    textAlign: "center",
    fontSize: "1rem",
    fontWeight: "bold",
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const Contact = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <List className={classes.listStyle}>
            <ListItem>
              <ListItemText primary="FOR ACCOUNTING INQUIRIES" />
            </ListItem>
            <Divider />
            <ListItem className={classes.listItemStyle}>
              <br />
            </ListItem>
            <ListItem className={classes.listItemStyle}>
              <ListItemIcon>
                <ContactPhoneOutlinedIcon style={{ color: blue[500] }} />
              </ListItemIcon>
              <ListItemText primary="Toll Free: 1.877.592.2972" />
            </ListItem>
            <ListItem className={classes.listItemStyle}>
              <ListItemIcon>
                <PrintOutlinedIcon style={{ color: purple[500] }} />
              </ListItemIcon>
              <ListItemText primary="Fax: 604-592-2982" />
            </ListItem>
            <ListItem className={classes.lastItemStyle}>
              <ListItemIcon>
                <EmailOutlinedIcon style={{ color: orange[500] }} />
              </ListItemIcon>
              <ListItemText primary="Email: " />
              <ListItem>
                <Link
                  href="mailto:apayaccounting@productcare.org"
                  color="inherit"
                >
                  apayaccounting@productcare.org
                </Link>
              </ListItem>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} sm={6}>
          <address>
            <List className={classes.listStyle}>
              <ListItem>
                <ListItemText primary="FOR SUBMISSION INQUIRIES" />
              </ListItem>
              <Divider />
              <ListItem className={classes.listItemStyle}>
                <br />
              </ListItem>
              <ListItem className={classes.listItemStyle}>
                <ListItemIcon>
                  <ContactPhoneOutlinedIcon style={{ color: blue[500] }} />
                </ListItemIcon>
                <ListItemText primary="Toll Free: 1.877.592.2972" />
              </ListItem>
              <ListItem className={classes.listItemStyle}>
                <ListItemIcon>
                  <PrintOutlinedIcon style={{ color: purple[500] }} />
                </ListItemIcon>
                <ListItemText primary="Fax: 604-592-2982" />
              </ListItem>
              <ListItem className={classes.listItemStyle}>
                <ListItemIcon>
                  <EmailOutlinedIcon style={{ color: orange[500] }} />
                </ListItemIcon>
                <ListItemText primary="Email: " />
                <ListItem>
                  <Link href="mailto:ONclaims@productcare.org" color="inherit">
                    ONclaims@productcare.org
                  </Link>
                </ListItem>
              </ListItem>
            </List>
          </address>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={10} sm={10}>
              <h2 className={classes.title}>
                Our head office is located in Vancouver, British Columbia. We
                have additional offices in Delta, Ontario, and Québec.
              </h2>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={7} sm={7} className={classes.bgSection}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={6} sm={6}>
                  <address>
                    <List>
                      <ListItem>
                        <ListItemIcon className={classes.centeredEl}>
                          <BusinessIcon
                            className={classes.iconHeadOffice}
                            title="Head Office"
                          />
                        </ListItemIcon>
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          className={classes.headOfficeSection}
                          disableTypography
                          primary={
                            <div>
                              420 – 2238 Yukon St
                              <br />
                              Vancouver BC, V5Y 3P2
                              <br />
                              Canada
                            </div>
                          }
                        />
                      </ListItem>
                    </List>
                  </address>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <address>
                    <List>
                      <ListItem>
                        <ListItemIcon className={classes.centeredEl}>
                          <PhoneIcon
                            className={classes.iconHeadOffice}
                            title="Head Office Phones"
                          />
                        </ListItemIcon>
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          className={classes.headOfficeSection}
                          disableTypography
                          primary={
                            <div>
                              604-592-2972 <br />
                              Toll Free: 1.877.592.2972
                              <br />
                              Fax: 604-592-2982
                            </div>
                          }
                        />
                      </ListItem>
                    </List>
                  </address>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Contact;
