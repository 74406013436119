import React from "react";
import { CircularProgress, Button } from "@material-ui/core";

const DownloadingButton = (props) => {
  const { onClick, loading, children } = props;
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      disabled={loading}
    >
      {loading && <CircularProgress size={14} />}
      {children}
    </Button>
  );
};

export default DownloadingButton;
