import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#586172",
    padding: theme.spacing(2),
    color: "#F7F7F7",
  },
}));

const Copyright = () => {
  return (
    <Typography variant="body2" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.productcare.org/" target="_blank">
        PCA
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const Footer = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <footer className={classes.footer} id="footer">
        <Copyright />
      </footer>
    </React.Fragment>
  );
};

export default Footer;
