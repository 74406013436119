import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";

const users = [
  {
    name: "Aaron Test",
    email: "aaronturecki@gmail.com",
    role: "Administrator",
    active: "Yes",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    overflowY: "hidden",
    overflowX: "hidden",
  },
}));

const getContactRoles = (contact) => {
  let roles = "";

  roles += contact.portalAdminRole ? " Administrator," : "";
  roles += contact.portalContractRole ? " Contract," : "";
  roles += contact.portalDataEntryRole ? " Data Entry," : "";
  roles += contact.portalFinanceRole ? " Finance," : "";
  roles += contact.portalRecipientRole ? " Recipient," : "";
  roles += contact.portalOperationsRole ? " Operations," : "";

  roles = roles !== "" ? roles.slice(0, -1) : roles;

  return roles;
};

const PortalUsersTable = ({ contacts, isLoading }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {isLoading && (
        <CircularProgress
          size={20}
          style={{ position: "absolute", zIndex: "999999" }}
        />
      )}
      <TableContainer component={Paper} elevation={0}>
        <Table
          className={classes.table}
          aria-label="customized table"
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Role</TableCell>
              <TableCell align="center">Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contacts.map((row) => (
              <TableRow key={row.contactId}>
                <TableCell component="th" scope="row" align="center">
                  {row.fullName}
                </TableCell>
                <TableCell align="center">{row.email}</TableCell>
                <TableCell align="center">{getContactRoles(row)}</TableCell>
                <TableCell align="center">
                  {row.stateCode === 0 ? (
                    <CheckCircleOutlineIcon color="primary" />
                  ) : (
                    <CancelOutlinedIcon color="primary" />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default PortalUsersTable;
