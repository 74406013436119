import {httpClient} from "../_helpers";

export const userService = {
    login,
    logout
};

function login(username, password) {
    return httpClient.post(`/contact/login`, {
        username: username,
        password: password
      })
      .then((response) => {

        if (!response.statusText === "Ok") {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
            }

            const error = (response.data && response.data.message) || response.statusText;
            return Promise.reject(error);
        }

        localStorage.setItem('user', JSON.stringify(response.data));
        return response.data;
    })
    .catch((err) => {
        return Promise.reject(err);
    });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    window.location.reload(true);
}

function handleResponse(response) {
    console.log(response);
    return response.then(text => {
        console.log(text);
        const data = text && JSON.parse(text);
        if (!response.statusText === "Ok") {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        console.log(data);
        return data;
    });
}
