import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import ClaimsList from "./ClaimsList";
import SubmissionList from "./SubmissionList";
import SubmissionDetails from "./SubmissionDetails";
import { TableContainer } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { submissionActions } from "../../../_actions";
import { claimActions } from "../../../_actions";
import { fileActions } from "../../../_actions";
import { toast } from "react-toastify";
import FilesList from "./FilesList";
import { Link } from "react-router-dom";
import SubmissionReport from "./SubmissionReportPdf";
import { CircularProgress, Button } from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import {format} from 'date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    overflowY: "hidden",
    overflowX: "auto",
    //overflowX: "hidden",
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    border: "1px #EEEEEE solid",
    background: "#FFFFFF",
  },
  schroll: {
    //overflowX: "auto",
  },
}));

const History = () => {

  var initialDateFrom = new Date();
  var initialDateTo = new Date();

  initialDateFrom.setMonth(initialDateTo.getMonth()-1);

  const [searching, setSearching] = useState(false);
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(initialDateFrom);
  const [selectedDateTo, setSelectedDateTo] = React.useState(initialDateTo);

  const handleDateChangeFrom = (date) => {
    setSelectedDateFrom(date);
  };
  const handleDateChangeTo = (date) => {
    setSelectedDateTo(date);
  };
  const dispatch = useDispatch();
  const {
    items,
    selected,
    loading,
    error,
    details,
    detailsLoading,
    detailsError,
  } = useSelector((state) => state.historySubmissions);
  const claims = useSelector((state) => state.claims);
  const files = useSelector((state) => state.files);
  const selectedSubmissionId = selected?.id;
  const historySubmissionColumns = [
    {
      title: "SubNo",
      field: "submissionNumber",
    },
    {
      title: "Title",
      field: "name",
    },
    {
      title: "Date",
      field: "submissionDate",
      type: "date",
    },
    {
      title: "Status",
      field: "statusCode",
    },
    {
      title: "Amount",
      field: "totalAmount",
    },
  ];

  const fileColumns = [
    {
      title: "Name",
      field: "documentName",
      render: (rowData) =>
        rowData !== "undefined" ? (
          <Link target="_blank" to={{ pathname: `${rowData.documentUrl}` }}>
            {rowData.documentName}
          </Link>
        ) : (
          ""
        ),
    },
  ];

  const submissionListOnRowClick = (event, rowData) => {
    dispatch(submissionActions.updateSelectedHistorySubmission(rowData));
  };

  useEffect(() => {
    dispatch(submissionActions.getHistorySubmissionsAndSelectFirst(format(selectedDateFrom, "yyyy-MM-dd"), format(selectedDateTo, "yyyy-MM-dd")));
  }, []);

  useEffect(() => {
    if (selectedSubmissionId) {
      dispatch(
        submissionActions.getHistorySubmissionById(selectedSubmissionId)
      );
      dispatch(fileActions.getBySubmissionId(selectedSubmissionId));
      dispatch(claimActions.getClaimsBySubmission(selectedSubmissionId));
    }
  }, selectedSubmissionId);

  const classes = useStyles();

  let searchSubmissions = (e) => {
    e.preventDefault();
    setSearching(true);

    if(isValidDate(selectedDateFrom) && isValidDate(selectedDateTo) && isAllowedDates(selectedDateFrom, selectedDateTo))
    {
      dispatch(submissionActions.getHistorySubmissionsAndSelectFirst(format(selectedDateFrom, "yyyy-MM-dd"), format(selectedDateTo, "yyyy-MM-dd")));
    }
    else
    {
      toast.warning("The search criteria for history submissions is invalid. Please fixe the search dates", {
        toastId: "history-submissions-search",
      });
    }

    //console.log(selectedDateFrom);
    //console.log(selectedDateTo);
    //dispatch(userActions.login(values.email, values.password));
    setSearching(false);
    console.log("details:");
    console.log(details);
    console.log("files:");
    console.log(files.items);
  };

  let isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  }

  let isAllowedDates = (datetimeStart, datetimeEnd) => 
  {
    if(Date.parse(datetimeStart) < Date.parse(datetimeEnd))
    {
      return true;
    }

    return false;
  }

  return (
    <div className={classes.root}>
      <Grid container padding={3} spacing={3}>
        <Grid item xs={12} sm={6}>
          <fieldset
            className={classes.paper}
            data-cy="submittedSubmissionContainer"
          >
            <legend>SUBMISSION LIST</legend>
            <div className='rowC'>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end">
                <Grid item xs={3} padding={5} spacing={5}>
                  <SubmissionReport data={selected} />
                </Grid>
                <Grid item xs={3}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="datePickerFrom"
                    label="Date From"
                    value={selectedDateFrom}
                    onChange={handleDateChangeFrom}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  </Grid>
                  <Grid item xs={3}>
                      <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="datePickerTo"
                      label="Date To"
                      value={selectedDateTo}
                      onChange={handleDateChangeTo}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} padding={5} spacing={5}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={searchSubmissions}
                      disabled={searching}
                    >
                      Search {searching && <CircularProgress size={14} />}
                    </Button>
                  </Grid>
              </Grid>
                </MuiPickersUtilsProvider>
          </div>
            
            <SubmissionList
              data={items}
              columns={historySubmissionColumns}
              onRowClick={submissionListOnRowClick}
              paging={false}
              isLoading={loading}
              selected={selectedSubmissionId}
            />
          </fieldset>
        </Grid>
        <Grid item xs={12} sm={6}>
          <fieldset className={classes.paper}>
            <legend>SUBMISSION DETAILS</legend>
            <SubmissionDetails data={details} isLoading={detailsLoading} />
          </fieldset>
          <br />
          <fieldset className={classes.paper} data-cy="submittedFilesContainer">
            <legend>FILES LIST</legend>
            <FilesList
              data={files.items}
              columns={fileColumns}
              paging={false}
              isLoading={files.loading}
            />
          </fieldset>
        </Grid>
        <Grid item xs={12} sm={12}>
          <fieldset className={classes.paper}>
            <legend>CLAIMS</legend>
            <div className={classes.schroll}>
              <ClaimsList
                data={claims.hcClaims}
                paging={false}
                isLoading={claims.hcLoading}
              />
            </div>
          </fieldset>
        </Grid>
      </Grid>
      <br />
      <br />
    </div>
  );
};

export default History;
