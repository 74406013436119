import React, { useState, useEffect, useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  fileActions,
} from "../../../_actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    overflowY: "hidden",
    overflowX: "hidden",
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    border: "1px #EEEEEE solid",
    background: "#FFFFFF"
  },
  button: {
    margin: theme.spacing(1),
    position: "absolute",
    zIndex: "999999",
    margin: "15px 2px 2px 50px",
  },
  ClaimsGridContainer: {
    //height: "100vh",
    overflowX: "auto",
    height: "100%",
    width: "100%",
  },
}));

const UploadFiles = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const submissionNumber = props.match.params.submissionNumber;

  //#region SELECTORS
  const fileInput = useRef(null);
  //#endregion

  //#region FILES TABLE EVENTS
  const filesTableRowAdd = () => {
    if (submissionNumber) {
      openFileUpload();
    }
  };
  const onChangeFileHandler = (event) => {
    const data = new FormData();
    data.append("submissionNumber", submissionNumber);

    if (event.target.files.length > 0) {
      event.target.files.forEach((file) => {
        data.append("files", file);
      });
    }
    dispatch(fileActions.uploadFiles(data));
  };
  const openFileUpload = () => {
    fileInput.current.click();
  };
  //#endregion
 
 return (
    <div className={classes.root}>
      <Grid container padding={3} spacing={3}>
        <Grid item xs={12}>
          <fieldset className={classes.paper} data-cy="documentsContainer">
            <legend>SUBMISSION/SHIPPING DOCUMENTS</legend>
            <div style={{ textAlign: "center", verticalAlign: "middle" }}>
              <input
                type="file"
                name="files"
                multiple
                onChange={onChangeFileHandler}
                ref={fileInput}
                style={{ display: "none" }}
              />
              <Button
                  variant="contained"
                  color="secondary"
                  padding={2}
                  spacing={2}
                  type="button"
                  onClick={filesTableRowAdd}
                >
                  Upload Files
                </Button>
            </div>
          </fieldset>
        </Grid>
      </Grid>
    </div>
  );
};

export default UploadFiles;
