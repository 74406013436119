import { httpClient } from "../_helpers";

export const announcementService = {
  getAnnouncements,
};

function getAnnouncements() {
  return httpClient
    .get("announcement/news")
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
