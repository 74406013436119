import React from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import HeaderNav from "./HeaderNav";
import AvatarNav from "./AvatarNav";
import { Link } from "react-router-dom";
import LanguageSelector from "./LanguageSelector";
import { userActions } from "../../../_actions";
import Button from "@material-ui/core/Button";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    padding: 15,
  },
  headerUserInfo: {
    backgroundColor: "#586172",
    padding: theme.spacing(1),
    color: "#F7F7F7",
    textAlign: "right",
    minHeight: "30px",
  },
}));

const Header = () => {
  const [auth, setAuth] = React.useState(true);
  const classes = useStyles();

  let loggedUser = JSON.parse(localStorage.getItem("user"));
  let loggedUserText = "";

  if (loggedUser) {
    loggedUserText = `${loggedUser.firstName} ${loggedUser.lastName} | ${loggedUser.servicePartnerName} |`;
  }

  const handleLogout = (e) => {
    e.preventDefault();
    userActions.logout();
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.headerUserInfo} id="headerUserInfo">
        {loggedUserText}
        {loggedUser && (
          <Link
            to="#"
            onClick={handleLogout}
            className={classes.headerUserInfo}
          >
            Logout
          </Link>
        )}
      </div>
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar>
          <Link to="/" className="logo">
            <img src={process.env.PUBLIC_URL + "/logo.svg"} />
          </Link>
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Service Partner Portal
          </Typography>
          {/* <LanguageSelector /> */}
          <HeaderNav />
          {/* <AvatarNav /> */}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Header;
