import React from "react";

import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HistoryIcon from "@material-ui/icons/History";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import PublishIcon from "@material-ui/icons/Publish";
import Button from "@material-ui/core/Button";
import { Link, NavLink } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Menu from "@material-ui/core/Menu";

const useStyles = makeStyles((theme) => ({
  button: {
    "&.active": {
      backgroundColor: "rgba(1, 109, 99, 0.7)",
    },
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const HeaderNav = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const matches = useMediaQuery("(min-width:600px)");
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (page) => {
    if (page.length > 0) {
      history.push("/" + page);
    }

    setAnchorEl(null);
  };

  return (
    <div>
      {matches && (
        <div>
          <Button
            color="inherit"
            component={NavLink}
            to={"/account"}
            className={classes.button}
            data-cy="navAccount"
          >
            Account
          </Button>
          <Button
            color="inherit"
            component={NavLink}
            to={"/submissions"}
            className={classes.button}
            data-cy="navSubmissions"
          >
            Submissions
          </Button>
          <Button
            color="inherit"
            component={NavLink}
            to={"/history"}
            className={classes.button}
            data-cy="navHistory"
          >
            History
          </Button>
          <Button
            color="inherit"
            component={NavLink}
            to={"/contact"}
            className={classes.button}
            data-cy="navContact"
          >
            Contact
          </Button>
        </div>
      )}
      {!matches && (
        <div>
          <IconButton
            edge="start"
            onClick={handleMenu}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => handleClose("")}
          >
            <MenuItem onClick={() => handleClose("account")}>
              <ListItemIcon>
                <AccountCircle fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Account" />
            </MenuItem>
            <MenuItem onClick={() => handleClose("submissions")}>
              <ListItemIcon>
                <PublishIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Submission" />
            </MenuItem>
            <MenuItem onClick={() => handleClose("history")}>
              <ListItemIcon>
                <HistoryIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="History" />
            </MenuItem>
            <MenuItem onClick={() => handleClose("contact")}>
              <ListItemIcon>
                <ContactMailIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Contact" />
            </MenuItem>
          </StyledMenu>
        </div>
      )}
    </div>
  );
};

export default HeaderNav;
