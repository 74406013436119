import React, { useState } from "react";
import { httpClient } from "../../../_helpers";
import DownloadingButton from "../../DownloadingButton";
import { toast } from "react-toastify";

const SubmissionReportPdf = (props) => {
  const [loading, setLoading] = useState(false);
  const { id, submissionNumber, name } = props.data;
  const handleClick = () => {
    
    if (id) {
      setLoading(true);
      httpClient
        .get(`/submission/pdfreport/${id}`, { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${submissionNumber}_${name}.pdf`.replace(/\s+|-/g, '_'));
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setLoading(false);
          toast.success("SUCCESS: Report downloaded successfully.");
        })
        .catch((err) => {
          toast.error("ERROR: Failed to download report.");
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <DownloadingButton onClick={handleClick} loading={loading}>
        Download Report
      </DownloadingButton>
    </div>
  );
};

export default SubmissionReportPdf;
