import { accountConstants } from "../_constants";

const initialState = {
    account: {},
    loading: false,
    error: null,
};

export function accounts(state = initialState, action) {
    switch (action.type) {
        case accountConstants.GET_BYID_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case accountConstants.GET_BYID_SUCCESS:
            return {
                ...state,
                account: action.account,
                loading: false,
                error: null,
            };
        case accountConstants.GET_BYID_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        default:
            return state;
    }
}


const pInitialState = {
    items: [],
    loading: false,
    error: null,
};
export function processors(state = pInitialState, action) {
    switch (action.type) {
        case accountConstants.ACCOUNT_GET_PROCESSORS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case accountConstants.ACCOUNT_GET_PROCESSORS_SUCCESS:
            return {
                ...state,
                items: action.items,
                loading: false,
                error: null,
            };
        case accountConstants.ACCOUNT_GET_PROCESSORS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        default:
            return state;
    }
}

const tInitialState = {
    items: [],
    loading: false,
    error: null,
};
export function transporters(state = tInitialState, action) {
    switch (action.type) {
        case accountConstants.ACCOUNT_GET_TRANSPORTERS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case accountConstants.ACCOUNT_GET_TRANSPORTERS_SUCCESS:
            return {
                ...state,
                items: action.items,
                loading: false,
                error: null,
            };
        case accountConstants.ACCOUNT_GET_TRANSPORTERS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        default:
            return state;
    }
}
