import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import LocationsTable from "./LocationsTable";
import PortalUsersTable from "./PortalUsersTable";
import CompanyInformation from "./CompanyInformation";

import { useDispatch, useSelector } from "react-redux";
import {
  accountActions,
  locationActions,
  contactActions,
} from "../../../_actions";

import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    overflowY: "hidden",
    overflowX: "hidden",
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    border: "1px #EEEEEE solid",
    background: "#FFFFFF",
  },
}));

const Account = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const accounts = useSelector((state) => state.accounts);
  const locations = useSelector((state) => state.locations);
  const contacts = useSelector((state) => state.contacts);

  const user = JSON.parse(localStorage.getItem("user"));
  const servicePartnerId =
    user && user.servicePartner ? user.servicePartner : "";

  useEffect(() => {
    dispatch(accountActions.getById(servicePartnerId));
    dispatch(locationActions.getByServicePartnerId(servicePartnerId));
    dispatch(contactActions.getByServicePartnerId(servicePartnerId));
  }, []);

  if (accounts.error) {
    toast.error(accounts.error, {
      toastId: "accounts-error",
    });
  }
  if (locations.error) {
    toast.error(locations.error, {
      toastId: "locations-error",
    });
  }
  if (contacts.error) {
    toast.error(contacts.error, {
      toastId: "contacts-error",
    });
  }
  return (
    <div className={classes.root}>
      <Grid container padding={5} spacing={5}>
        <Grid item xs={12} sm={6}>
          <fieldset className={classes.paper}>
            <legend>COMPANY/MUNICIPAL INFORMATION</legend>
            <CompanyInformation
              account={accounts.account}
              isLoading={accounts.loading}
            />
          </fieldset>
        </Grid>
        <Grid item xs={12} sm={6}>
          <fieldset className={classes.paper}>
            <legend>LOCATIONS</legend>
            <LocationsTable
              locations={locations.items}
              isLoading={locations.loading}
            />
          </fieldset>
        </Grid>
        <Grid item xs={12} sm={6}>
          <fieldset className={classes.paper}>
            <legend>REGISTERED PORTAL USERS</legend>
            <PortalUsersTable
              contacts={contacts.items}
              isLoading={contacts.loading}
            />
          </fieldset>
          <br />
          <br />
        </Grid>
      </Grid>
    </div>
  );
};

export default Account;
