import { announcementConstants } from "../_constants";
import { announcementService } from "../_services";

export const announcementActions = {
  getAnnouncements,
};

function getAnnouncements() {
  return (dispatch) => {
    dispatch(request());

    announcementService.getAnnouncements().then(
      (items) => dispatch(success(items)),
      (error) => dispatch(failure("ERROR: Failed to fetch announcements."))
    );
  };

  function request() {
    return { type: announcementConstants.GET_ALL_REQUEST };
  }
  function success(items) {
    return { type: announcementConstants.GET_ALL_SUCCESS, items };
  }
  function failure(error) {
    return { type: announcementConstants.GET_ALL_FAILURE, error };
  }
}
