import React, { useState, useEffect } from "react";
import { httpClient } from "../_helpers";
import ResetPasswordForm from "./ResetPasswordForm";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2, 2, 8),
    overflowY: "hidden",
    overflowX: "hidden",
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

const ResetPassword = (props) => {

  const classes = useStyles();
  const [isLinkValid, SetIsLinkValid] = useState(2);

  useEffect(() => {
    console.log(props.match.params.id, props.match.params.code);
    httpClient
      .get(`/contact/validatecode/${props.match.params.code}`)
      .then((response) => {
        SetIsLinkValid(1);
        //toast.success("SUCCESS: Report downloaded successfully.");
      })
      .catch((err) => {
        if(err.response){
          if (err.response.status === 400) {
            SetIsLinkValid(0);
          }
        }
        //toast.error("ERROR: Failed to download report.");
      });
  }, []);


  const isFormSubmitted = () => {
    SetIsLinkValid(3);
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={12}>
          {isLinkValid === 1 ? (
            <ResetPasswordForm isFormSubmitted={isFormSubmitted} id={props.match.params.id}/>
          ) : isLinkValid === 0 ? (
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Paper className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <ErrorOutlineIcon />
                </Avatar>
                <Typography component="h6" variant="h6">
                  Error
                </Typography>
                <Typography>
                  Your url link format is incorrect or code is expired. Please, request your password change again
                </Typography>
                <Link href="/forgotPassword" variant="body2">
                  Back to Forgot password page
                </Link>
              </Paper>
            </Container>
          ) : isLinkValid === 3 ? (
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Paper className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <ErrorOutlineIcon />
                </Avatar>
                <Typography component="h6" variant="h6">
                  Success!
                </Typography>
                <Typography>
                  Password has been reset. Please use link below to login
                </Typography>
                <Link href="/" variant="body2">
                  Home page
                </Link>
              </Paper>
            </Container>
          ) : <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <ErrorOutlineIcon />
            </Avatar>
            <Typography component="h6" variant="h6">
              Please wait
            </Typography>
            <Typography>
              Validating Link...
            </Typography>
          </Paper>
        </Container>}
        </Grid>
      </Grid>
    </div>
  );
};

export default ResetPassword;
