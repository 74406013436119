export const submissionConstants = {
  SUBMISSION_GET_LIST: "SUBMISSION_GET_LIST",
  SUBMISSION_GET_BYID: "SUBMISSION_GET_BYID",

  SUBMISSION_HISTORY_GET_LIST_REQUEST: "SUBMISSION_HISTORY_GET_LIST_REQUEST",
  SUBMISSION_HISTORY_GET_LIST_SUCCESS: "SUBMISSION_HISTORY_GET_LIST_SUCCESS",
  SUBMISSION_HISTORY_GET_LIST_FAILURE: "SUBMISSION_HISTORY_GET_LIST_FAILURE",

  SUBMISSION_HISTORY_GET_BYID_REQUEST: "SUBMISSION_HISTORY_GET_BYID_REQUEST",
  SUBMISSION_HISTORY_GET_BYID_SUCCESS: "SUBMISSION_HISTORY_GET_BYID_SUCCESS",
  SUBMISSION_HISTORY_GET_BYID_FAILURE: "SUBMISSION_HISTORY_GET_BYID_FAILURE",

  SUBMISSION_HISTORY_UPDATE_SELECTED: "SUBMISSION_HISTORY_UPDATE_SELECTED",

  GET_UNSUBMITTED_REQUEST: "SUBMISSION_UNSUBMITTED_GET_ALL_REQUEST",
  GET_UNSUBMITTED_SUCCESS: "SUBMISSION_UNSUBMITTED_GET_ALL_SUCCESS",
  GET_UNSUBMITTED_FAILURE: "SUBMISSION_UNSUBMITTED_GET_ALL_FAILURE",

  POST_UNSUBMITTED_REQUEST: "SUBMISSION_UNSUBMITTED_POST_REQUEST",
  POST_UNSUBMITTED_SUCCESS: "SUBMISSION_UNSUBMITTED_POST_SUCCESS",
  POST_UNSUBMITTED_FAILURE: "SUBMISSION_UNSUBMITTED_POST_FAILURE",

  DUPLICATE_SUBMISSION_REQUEST: "DUPLICATE_SUBMISSION_REQUEST",
  DUPLICATE_SUBMISSION_SUCCESS: "DUPLICATE_SUBMISSION_SUCCESS",
  DUPLICATE_SUBMISSION_FAILURE: "DUPLICATE_SUBMISSION_FAILURE",

  PUT_UNSUBMITTED_REQUEST: "SUBMISSION_UNSUBMITTED_PUT_REQUEST",
  PUT_UNSUBMITTED_SUCCESS: "SUBMISSION_UNSUBMITTED_PUT_SUCCESS",
  PUT_UNSUBMITTED_FAILURE: "SUBMISSION_UNSUBMITTED_PUT_FAILURE",

  DELETE_UNSUBMITTED_REQUEST: "SUBMISSION_UNSUBMITTED_DELETE_REQUEST",
  DELETE_UNSUBMITTED_SUCCESS: "SUBMISSION_UNSUBMITTED_DELETE_SUCCESS",
  DELETE_UNSUBMITTED_FAILURE: "SUBMISSION_UNSUBMITTED_DELETE_FAILURE",

  SUBMIT_UNSUBMITTED_REQUEST: "SUBMISSION_SUBMIT_REQUEST",
  SUBMIT_UNSUBMITTED_SUCCESS: "SUBMISSION_SUBMIT_SUCCESS",
  SUBMIT_UNSUBMITTED_FAILURE: "SUBMISSION_SUBMIT_FAILURE",

  UPDATE_SELECTED_UNSUBMITTED_SUBMISSION:
    "SUBMISSION_UNSUBMITTED_UPDATE_SELECTED",
};
