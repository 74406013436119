import React from "react";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { orange, blue, purple, red, green } from "@material-ui/core/colors";
import { useSelector } from "react-redux";
import SignIn from "../SignIn";
import SignedIn from "../SignedIn";
import { useTranslation } from "react-i18next";
import AnnouncementsList from "../AnnouncementsList";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(5, 0, 6),
  },
  linkHome: {
    color: "primary",
  },
}));

const Home = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isLoggedIn = useSelector((state) => state.authentication.loggedIn);

  return (
    <React.Fragment>
      <Grid container direction="row">
        <Grid item xs={12} sm={6}>
          <div className={classes.heroContent}>
            <Container maxWidth="sm">
              <Typography variant="h5" color="textSecondary" paragraph>
                {t("home.title")}
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleOutlineIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Please log in to submit a claim, to review the status of a claim, or to review your account information." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleOutlineIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <div>
                        If you have any questions,{" "}
                        <NavLink to="/contact" className={classes.linkHome}>
                          please contact us
                        </NavLink>
                        .
                      </div>
                    }
                  />
                </ListItem>
              </List>
            </Container>
            <AnnouncementsList />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          {!isLoggedIn ? <SignIn /> : <SignedIn />}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Home;
