import { httpClient } from "../_helpers";

export const submissionService = {
  getHistorySubmissionById,
  getHistorySubmissions,
  getUnsubmittedSubmissions,
  duplicateSubmission,
  postUnsubmittedSubmission,
  putUnsubmittedSubmission,
  deleteUnsubmittedSubmission,
  submitUnsubmittedSubmission,
};

function getHistorySubmissionById(id) {
  return httpClient
    .get(`/submission/get/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function getHistorySubmissions(fromDate, toDate) {
  let user = JSON.parse(localStorage.getItem("user"));

  return httpClient
    .get(`/submission/servicepartner/historySubmissions/${user.servicePartner}/${fromDate}/${toDate}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function getUnsubmittedSubmissions() {
  let user = JSON.parse(localStorage.getItem("user"));

  return httpClient
    .get(
      `/submission/servicepartner/${user.servicePartner}/UnsubmittedSubmissions`
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function duplicateSubmission(submissionId) {
  let user = JSON.parse(localStorage.getItem("user"));

  return httpClient
    .get(
      `/submission/duplicate/${user.servicePartner}/${submissionId}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function postUnsubmittedSubmission(submission) {
  return httpClient
    .post("/submission", submission)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function putUnsubmittedSubmission(submission) {
  return httpClient
    .put("/submission", submission)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function deleteUnsubmittedSubmission(id) {
  return httpClient
    .delete(`/submission/delete/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function submitUnsubmittedSubmission(id) {
  return httpClient
    .put("/submission/submit", { Id: id })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
