import { locationConstants } from "../_constants";

const initialState = {
    items: [],
    loading: false,
    error: null,
};

export function locations(state = initialState, action) {
    switch (action.type) {
        case locationConstants.GET_ALL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case locationConstants.GET_ALL_SUCCESS:
            return {
                ...state,
                items: action.items,
                loading: false,
            };
        case locationConstants.GET_ALL_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        default:
            return state;
    }
}

const csInitialState = {
    items: [],
    loading: false,
    error: null,
};

export function collectionSites(state = csInitialState, action) {
    switch (action.type) {
        case locationConstants.GET_COLLECTIONSITES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case locationConstants.GET_COLLECTIONSITES_SUCCESS:
            return {
                ...state,
                items: action.items,
                loading: false,
            };
        case locationConstants.GET_COLLECTIONSITES_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        default:
            return state;
    }
}
