import { submissionConstants } from "../_constants";
import { submissionService } from "../_services";
import { toast } from "react-toastify";

export const submissionActions = {
  getSubmissions,
  getHistorySubmissionById,
  getHistorySubmissions,
  getHistorySubmissionsAndSelectFirst,
  getUnsubmittedSubmissions,
  duplicateSubmission,
  postUnsubmittedSubmission,
  putUnsubmittedSubmission,
  deleteUnsubmittedSubmission,
  submitUnsubmittedSubmission,
  updateSelectedUnsubmittedSubmission,
  updateSelectedHistorySubmission,
};

function getSubmissions() {
  return (dispatch) => {
    let submissions = [
      {
        submissionNumber: "ONP-1345",
        title: "Transport Testtttttt",
        date: "06/01/2016",
        status: "Reversed",
        amount: "120.00",
      },
      {
        submissionNumber: "ONP-1346",
        title: "Transport Test2",
        date: "06/01/2016",
        status: "Reversed",
        amount: "125.00",
      },
    ];
    dispatch(updateSubmissionsList(submissions));
  };
}

export function updateSubmissionsList(submissions) {
  return {
    type: submissionConstants.SUBMISSION_GET_LIST,
    payload: submissions,
  };
}

function getHistorySubmissionById(id) {
  return (dispatch) => {
    dispatch(request());

    submissionService.getHistorySubmissionById(id).then(
      (submission) => dispatch(success(submission)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return {
      type: submissionConstants.SUBMISSION_HISTORY_GET_BYID_REQUEST,
    };
  }
  function success(submission) {
    return {
      type: submissionConstants.SUBMISSION_HISTORY_GET_BYID_SUCCESS,
      submission,
    };
  }
  function failure(error) {
    return {
      type: submissionConstants.SUBMISSION_HISTORY_GET_BYID_FAILURE,
      error,
    };
  }
}

function getHistorySubmissions() {
  return (dispatch) => {
    dispatch(request());

    submissionService.getHistorySubmissions().then(
      (submissions) => dispatch(success(submissions)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return {
      type: submissionConstants.SUBMISSION_HISTORY_GET_LIST_REQUEST,
    };
  }
  function success(submissions) {
    return {
      type: submissionConstants.SUBMISSION_HISTORY_GET_LIST_SUCCESS,
      submissions,
    };
  }
  function failure(error) {
    return {
      type: submissionConstants.SUBMISSION_HISTORY_GET_LIST_FAILURE,
      error,
    };
  }
}

function getHistorySubmissionsAndSelectFirst(fromDate, toDate) {
  return (dispatch) => {
    dispatch(request());

    submissionService.getHistorySubmissions(fromDate, toDate).then(
      (submissions) => {
        dispatch(success(submissions));
        if (typeof submissions != "undefined" && submissions.length > 0) {
          dispatch(select(submissions[0]));
        }
        else
        {
          dispatch(select({id: '00000000-0000-0000-0000-000000000000' }));
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return {
      type: submissionConstants.SUBMISSION_HISTORY_GET_LIST_REQUEST,
    };
  }
  function success(submissions) {
    return {
      type: submissionConstants.SUBMISSION_HISTORY_GET_LIST_SUCCESS,
      submissions,
    };
  }
  function select(submission) {
    return {
      type: submissionConstants.SUBMISSION_HISTORY_UPDATE_SELECTED,
      submission,
    };
  }
  function failure(error) {
    return {
      type: submissionConstants.SUBMISSION_HISTORY_GET_LIST_FAILURE,
      error,
    };
  }
}

function getUnsubmittedSubmissions() {
  return (dispatch) => {
    dispatch(request());

    submissionService.getUnsubmittedSubmissions().then(
      (submissions) => dispatch(success(submissions)),
      (error) =>
        dispatch(failure("ERROR: Failed to fetch unfinished submissions!"))
    );
  };

  function request() {
    return { type: submissionConstants.GET_UNSUBMITTED_REQUEST };
  }
  function success(submissions) {
    return {
      type: submissionConstants.GET_UNSUBMITTED_SUCCESS,
      submissions,
    };
  }
  function failure(error) {
    return { type: submissionConstants.GET_UNSUBMITTED_FAILURE, error };
  }
}

function postUnsubmittedSubmission(submission) {
  return (dispatch) => {
    dispatch(request());

    submissionService.postUnsubmittedSubmission(submission).then(
      (response) => {
        toast.success("Submission has been created");
        dispatch(getUnsubmittedSubmissions());
      },
      (error) => {
        toast.error("Failed to create Submission");
        dispatch(failure("ERROR: Failed to add unfinished submission!"));
      }
    );
  };

  function request() {
    return { type: submissionConstants.POST_UNSUBMITTED_REQUEST };
  }

  function failure(error) {
    return { type: submissionConstants.POST_UNSUBMITTED_FAILURE, error };
  }
}




function duplicateSubmission(submissionId) {
  return (dispatch) => {
    dispatch(request());

    submissionService.duplicateSubmission(submissionId).then(
      (response) => {
        toast.success("Submission has been duplicated");
        dispatch(getUnsubmittedSubmissions());
      },
      (error) => {
        toast.error("Failed to duplicate Submission");
        dispatch(failure("ERROR: Failed to duplicate submission"));
      }
    );
  };
  function request() {
    return { type: submissionConstants.DUPLICATE_SUBMISSION_REQUEST };
  }

  function failure(error) {
    return { type: submissionConstants.DUPLICATE_SUBMISSION_FAILURE, error };
  }
}


function putUnsubmittedSubmission(submission) {
  return (dispatch) => {
    dispatch(request());

    submissionService.putUnsubmittedSubmission(submission).then(
      (response) => {
        toast.success("Submission has been updated");
        dispatch(getUnsubmittedSubmissions());
      },
      (error) => {
        toast.error("Failed to update Submission");
        dispatch(failure("ERROR: Failed to update unfinished submission!"));
      }
    );
  };

  function request() {
    return { type: submissionConstants.PUT_UNSUBMITTED_REQUEST };
  }

  function failure(error) {
    return { type: submissionConstants.PUT_UNSUBMITTED_FAILURE, error };
  }
}

function deleteUnsubmittedSubmission(id) {
  return (dispatch) => {
    dispatch(request());

    submissionService.deleteUnsubmittedSubmission(id).then(
      (response) => {
        toast.success("Submission has been deleted");
        dispatch(success());
        dispatch(getUnsubmittedSubmissions());
      },
      (error) => {
        toast.error("Failed to delete Submission");
        dispatch(failure("ERROR: Failed to delete unfinished submission!"));
      }
    );
  };

  function request() {
    return { type: submissionConstants.DELETE_UNSUBMITTED_REQUEST };
  }

  function success() {
    return { type: submissionConstants.DELETE_UNSUBMITTED_SUCCESS };
  }

  function failure(error) {
    return { type: submissionConstants.DELETE_UNSUBMITTED_FAILURE, error };
  }
}

function submitUnsubmittedSubmission(id) {
  return (dispatch) => {
    dispatch(request());

    submissionService.submitUnsubmittedSubmission(id).then(
      (response) => {
        toast.success("Submission has been submitted");
        dispatch(success());
        dispatch(getUnsubmittedSubmissions());
      },
      (error) => {
        toast.error("Failed to submit Submission");
        dispatch(failure("ERROR: Failed to submit unfinished submission!"));
      }
    );
  };

  function request() {
    return { type: submissionConstants.SUBMIT_UNSUBMITTED_REQUEST };
  }

  function success() {
    return { type: submissionConstants.SUBMIT_UNSUBMITTED_SUCCESS };
  }

  function failure(error) {
    return { type: submissionConstants.SUBMIT_UNSUBMITTED_FAILURE, error };
  }
}

function updateSelectedUnsubmittedSubmission(submission) {
  return (dispatch) => {
    dispatch(request(submission));
  };

  function request(submission) {
    return {
      type: submissionConstants.UPDATE_SELECTED_UNSUBMITTED_SUBMISSION,
      submission,
    };
  }
}

function updateSelectedHistorySubmission(submission) {
  return (dispatch) => {
    dispatch(request(submission));
  };

  function request(submission) {
    return {
      type: submissionConstants.SUBMISSION_HISTORY_UPDATE_SELECTED,
      submission,
    };
  }
}
