import { claimConstants } from "../_constants";
import { claimService } from "../_services";
import { toast } from "react-toastify";

export const claimActions = {
  getClaimsBySubmission,
  getMaterialCategories,
  getClaimsBySubmissionForSubmission,
  getContainerTypes,
  getBulbTypes,
  getServiceTypes,
  getUnits,
  postClaim,
  updateClaim,
  deleteClaim,
};

function getClaimsBySubmission(id) {
  return (dispatch) => {
    dispatch(request());

    claimService.getClaimsBySubmission(id).then(
      (claims) => dispatch(success(claims)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: claimConstants.CLAIM_GET_BYSUBMISSION_REQUEST };
  }
  function success(claims) {
    return { type: claimConstants.CLAIM_GET_BYSUBMISSION_SUCCESS, claims };
  }
  function failure(error) {
    return { type: claimConstants.CLAIM_GET_BYSUBMISSION_FAILURE, error };
  }
}

function getClaimsBySubmissionForSubmission(id) {
  return (dispatch) => {
    dispatch(request());

    claimService.getClaimsBySubmission(id).then(
      (claims) => dispatch(success(claims)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: claimConstants.CLAIM_GET_BYSUBMISSION_FS_REQUEST };
  }
  function success(claims) {
    return { type: claimConstants.CLAIM_GET_BYSUBMISSION_FS_SUCCESS, claims };
  }
  function failure(error) {
    return { type: claimConstants.CLAIM_GET_BYSUBMISSION_FS_FAILURE, error };
  }
}

function getMaterialCategories() {
  return (dispatch) => {
    dispatch(request());

    claimService.getMaterialCategories().then(
      (items) => dispatch(success(items)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: claimConstants.CLAIM_GET_MATERIALCATEGORIES_REQUEST };
  }
  function success(items) {
    return { type: claimConstants.CLAIM_GET_MATERIALCATEGORIES_SUCCESS, items };
  }
  function failure(error) {
    return { type: claimConstants.CLAIM_GET_MATERIALCATEGORIES_FAILURE, error };
  }
}

function getContainerTypes() {
  return (dispatch) => {
    dispatch(request());

    claimService.getContainerTypes().then(
      (items) => dispatch(success(items)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: claimConstants.CLAIM_GET_CONTAINERTYPES_REQUEST };
  }
  function success(items) {
    return { type: claimConstants.CLAIM_GET_CONTAINERTYPES_SUCCESS, items };
  }
  function failure(error) {
    return { type: claimConstants.CLAIM_GET_CONTAINERTYPES_FAILURE, error };
  }
}

function getBulbTypes() {
  return (dispatch) => {
    dispatch(request());

    claimService.getBulbTypes().then(
      (items) => dispatch(success(items)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: claimConstants.CLAIM_GET_BULBTYPES_REQUEST };
  }
  function success(items) {
    return { type: claimConstants.CLAIM_GET_BULBTYPES_SUCCESS, items };
  }
  function failure(error) {
    return { type: claimConstants.CLAIM_GET_BULBTYPES_FAILURE, error };
  }
}

function getServiceTypes() {
  return (dispatch) => {
    dispatch(request());

    claimService.getServiceTypes().then(
      (items) => dispatch(success(items)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: claimConstants.CLAIM_GET_SERVICETYPES_REQUEST };
  }
  function success(items) {
    return { type: claimConstants.CLAIM_GET_SERVICETYPES_SUCCESS, items };
  }
  function failure(error) {
    return { type: claimConstants.CLAIM_GET_SERVICETYPES_FAILURE, error };
  }
}

function getUnits() {
  return (dispatch) => {
    dispatch(request());

    claimService.getUnits().then(
      (items) => dispatch(success(items)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: claimConstants.CLAIM_GET_UNITS_REQUEST };
  }
  function success(items) {
    return { type: claimConstants.CLAIM_GET_UNITS_SUCCESS, items };
  }
  function failure(error) {
    return { type: claimConstants.CLAIM_GET_UNITS_FAILURE, error };
  }
}

function postClaim(item, submissionId) {
  return (dispatch) => {
    dispatch(request());
    claimService.postClaim(item, submissionId).then(
      function (response) {
        toast.success("Claim has been created");
        dispatch(getClaimsBySubmissionForSubmission(submissionId));
      },
      function (error) {
        toast.error("Failed to create claim");
        dispatch(failure("ERROR: Failed to add claim"));
      }
    );
  };

  function request() {
    return { type: claimConstants.POST_CLAIM_REQUEST };
  }

  function failure(error) {
    return { type: claimConstants.POST_CLAIM_FAILURE, error };
  }
}

function updateClaim(item, submissionId) {
  return (dispatch) => {
    dispatch(request());

    claimService.updateClaim(item).then(
      function (response) {
        toast.success("Claim has been updated");
        dispatch(getClaimsBySubmissionForSubmission(submissionId));
      },
      function (error) {
        toast.error("Failed to update claim");
        dispatch(failure("ERROR: Failed to update claim!"));
      }
    );
  };

  function request() {
    return { type: claimConstants.PUT_CLAIM_REQUEST };
  }

  function failure(error) {
    return { type: claimConstants.PUT_CLAIM_FAILURE, error };
  }
}

function deleteClaim(id, submissionId) {
  return (dispatch) => {
    dispatch(request());

    claimService.deleteClaim(id).then(
      function (response) {
        toast.success("Claim has been deleted");
        dispatch(getClaimsBySubmissionForSubmission(submissionId));
      },
      function (error) {
        toast.error("Failed to delete claim");
        dispatch(failure("ERROR: Failed to delete claim!"));
      }
    );
  };

  function request() {
    return { type: claimConstants.DELETE_CLAIM_REQUEST };
  }

  function failure(error) {
    return { type: claimConstants.DELETE_CLAIM_FAILURE, error };
  }
}
