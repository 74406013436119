import { httpClient } from "../_helpers";

export const fileService = {
    getBySubmissionId,
    uploadDocument,
    uploadFiles,
    deleteDocument,
};

function getBySubmissionId(id) {
    return httpClient
        .get(`/submissionDocument/submission/${id}`)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}

function uploadDocument(data) {
    return httpClient
        .post("/submissionDocument/upload", data)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}

function uploadFiles(data) {
    return httpClient
        .post("/submissionDocument/uploadFiles", data)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}

function deleteDocument(documentId) {
    return httpClient
        .delete(`/submissionDocument/delete/${documentId}`)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
