import { fileConstants } from "../_constants";
import { fileService } from "../_services";
import { toast } from "react-toastify";

export const fileActions = {
  getBySubmissionId,
  uploadDocument,
  uploadFiles,
  getDocumentsBySubmissionId,
  deleteDocument,
};

function getBySubmissionId(id) {
  return (dispatch) => {
    dispatch(request());

    fileService.getBySubmissionId(id).then(
      (items) => dispatch(success(items)),
      (error) => dispatch(failure("ERROR: Failed to fetch submission files."))
    );
  };

  function request() {
    return { type: fileConstants.GET_ALL_REQUEST };
  }
  function success(items) {
    return { type: fileConstants.GET_ALL_SUCCESS, items };
  }
  function failure(error) {
    return { type: fileConstants.GET_ALL_FAILURE, error };
  }
}

function uploadDocument(data, submissionId) {
  return (dispatch) => {
    dispatch(request());

    fileService.uploadDocument(data).then(
      () => {
        toast.success("Documents has been uploaded");
        dispatch(success());
        dispatch(getDocumentsBySubmissionId(submissionId));
      },
      () => {
        toast.error("Failed to upload Documents");
        dispatch(failure("ERROR: Failed to upload submission document."));
      }
    );
  };

  function request() {
    return { type: fileConstants.SUBMISSION_DOCUMENT_UPLOAD_REQUEST };
  }
  function success() {
    return { type: fileConstants.SUBMISSION_DOCUMENT_UPLOAD_SUCCESS };
  }
  function failure(error) {
    return {
      type: fileConstants.SUBMISSION_DOCUMENT_UPLOAD_FAILURE,
      error,
    };
  }
}

function uploadFiles(data) {
  return (dispatch) => {
    dispatch(request());

    fileService.uploadFiles(data).then(
      () => {
        toast.success("Documents has been uploaded");
        dispatch(success());
      },
      () => {
        toast.error("Failed to upload Documents");
        dispatch(failure("ERROR: Failed to upload submission documents."));
      }
    );
  };

  function request() {
    return { type: fileConstants.SUBMISSION_DOCUMENT_UPLOAD_REQUEST };
  }
  function success() {
    return { type: fileConstants.SUBMISSION_DOCUMENT_UPLOAD_SUCCESS };
  }
  function failure(error) {
    return {
      type: fileConstants.SUBMISSION_DOCUMENT_UPLOAD_FAILURE,
      error,
    };
  }
}

function getDocumentsBySubmissionId(id) {
  return (dispatch) => {
    dispatch(request());

    fileService.getBySubmissionId(id).then(
      (items) => dispatch(success(items)),
      (error) => dispatch(failure("ERROR: Failed to fetch submission files."))
    );
  };

  function request() {
    return { type: fileConstants.GET_ALL_SUBMISSION_REQUEST };
  }
  function success(items) {
    return { type: fileConstants.GET_ALL_SUBMISSION_SUCCESS, items };
  }
  function failure(error) {
    return { type: fileConstants.GET_ALL_SUBMISSION_FAILURE, error };
  }
}

function deleteDocument(documentId, submissionId) {
  return (dispatch) => {
    dispatch(request());

    fileService.deleteDocument(documentId).then(
      () => {
        toast.success("Document has been deleted");
        dispatch(success());
        dispatch(getDocumentsBySubmissionId(submissionId));
      },
      () => {
        toast.error("Failed to delete Document");
        dispatch(failure("ERROR: Failed to delete submission document."));
      }
    );
  };

  function request() {
    return { type: fileConstants.SUBMISSION_DOCUMENT_DELETE_REQUEST };
  }
  function success() {
    return { type: fileConstants.SUBMISSION_DOCUMENT_DELETE_SUCCESS };
  }
  function failure(error) {
    return {
      type: fileConstants.SUBMISSION_DOCUMENT_DELETE_FAILURE,
      error,
    };
  }
}
