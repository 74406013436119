import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import CircularProgress from "@material-ui/core/CircularProgress";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../_actions";
import DownloadingButton from "./DownloadingButton";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({ email: "", password: "" });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const validatePassword = (e) => {
    const regex = /^.{6,}$/;
    return regex.test(String(e));
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("password" in fieldValues) {
      if (fieldValues.password) {
        if (!validatePassword(fieldValues.password)) {
          temp.password = "Password is invalid";
        } else {
          temp.password = "";
        }
      } else {
        temp.password = "Password is required";
      }
    }

    if ("email" in fieldValues) {
      if (fieldValues.email) {
        temp.email = "";
      } else {
        temp.email = "Username is required";
      }
    }

    setErrors({ ...temp });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  let handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      dispatch(userActions.login(values.email, values.password));
    } else {
      console.log("form invalid");
    }
  };

  const login = useSelector((state) => state.authentication);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label="Username"
            name="email"
            autoComplete="email"
            autoFocus
            value={values.email}
            onChange={handleInputChange}
            {...(errors.email && {
              error: true,
              helperText: errors.email,
            })}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={values.password}
            onChange={handleInputChange}
            {...(errors.password && {
              error: true,
              helperText: errors.password,
            })}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={login.loggingIn}
          >
            {login.loggingIn && (
              <CircularProgress
                size={14}
                style={{ left: 5, position: "absolute", zIndex: "999999" }}
              />
            )}
            Sign In
          </Button>
          {/* <DownloadingButton onClick={handleClick} loading={login.loggingIn}>
            Download Submission Report
          </DownloadingButton> */}
          <Grid container>
            <Grid item xs>
              <Link href="/forgotPassword" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              {/* {login.loggingIn && (
                <CircularProgress
                  size={20}
                  style={{ position: "absolute", zIndex: "999999" }}
                />
              )} */}
              {/* <Link href="#" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link> */}
            </Grid>
          </Grid>
        </form>
        <br />
        <br />
      </Paper>
    </Container>
  );
}
