import React from "react";
import MaterialTable from "material-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import { forwardRef } from "react";
import { Hidden, Paper } from "@material-ui/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ViewColumn from "@material-ui/icons/ViewColumn";
import {
  amber,
  blue,
  red,
  green,
  orange,
  grey,
  blueGrey,
} from "@material-ui/core/colors";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} style={{ color: blueGrey[500] }} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => (
    <Edit {...props} ref={ref} style={{ color: blueGrey[500] }} />
  )),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const ClaimsEditableTable = (props) => {
    
  var bulbTypeHidden = true;

  if(props.claims.length > 0)
  {
    if (props.claims.find(c => c.serviceType === 'Processing' && c.materialCategory === 'Lighting')) {
        bulbTypeHidden = false;
    }
  }
  
  var columns = [
    {
      title: "Service Type",
      field: "serviceType",
    },
    {
      title: "Document No.",
      field: "documentNumber",
    },
    {
      title: "Pickup Date",
      field: "pickupDate",
    },
    {
      title: "Delivery Date",
      field: "deliveryDate",
    },
    {
      title: "Collection Site",
      field: "collectionSite",
    },
    {
      title: "Transporter",
      field: "transporter",
    },
    {
      title: "Processor",
      field: "processor",
    },
    {
      title: "Material Category",
      field: "materialCategory",
    },
    {
      title: "Container Type",
      field: "containerType",
    },
    {
      title: "Bulb Type",
      field: "bulbType",
      hidden: bulbTypeHidden,
    },
    {
      title: "Quantity",
      field: "quantity",
      type: "numeric",
    },
    {
      title: "Weight(kg)",
      field: "weight",
      type: "numeric",
    },
    {
      title: "Unit",
      field: "unit",
    },
  ];

  return (
    <React.Fragment>
      {/* {props.isLoading && (
        <CircularProgress
          size={20}
          style={{ position: "absolute", zIndex: "999999" }}
        />
      )} */}
      <MaterialTable
        icons={tableIcons}
        isLoading={props.isLoading}
        title=""
        localization={{
          body: {
            editRow: {
              deleteText: "Are you sure you want to delete this claim?",
            },
          },
        }}
        columns={columns}
        data={props.claims}
        editable={{
          onRowDelete: (oldData) => props.rowDelete(oldData),
        }}
        options={{
          actionsColumnIndex: -1,
          paging: props.paging,
          // maxBodyHeight: 650,
          addRowPosition: "first",
        }}
        actions={[
          {
            icon: () => <FileCopyIcon style={{ color: blueGrey[500] }} />,
            tooltip: "Duplicate",
            onClick: (event, rowData) => {
              props.rowCopy(rowData);
            },
          },
          {
            icon: () => <AddBox color="primary" data-cy="addClaim" />,
            tooltip: "Add New Claim",
            // This makes add button to appear in table toolbar instead for each row
            isFreeAction: true,
            onClick: (event, rowData) => {
              props.rowAdd();
            },
          },
          {
            icon: () => <Edit style={{ color: blueGrey[500] }} />,
            tooltip: "Edit",
            onClick: (event, rowData) => {
              props.rowUpdate(rowData);
            },
          },
        ]}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
      />
    </React.Fragment>
  );
};

export default ClaimsEditableTable;
