import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { useDispatch, useSelector } from "react-redux";
import { httpClient } from "../_helpers";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ResetPasswordForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({ password: "", confirm_password: "" });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const validatePassword = (e) => {
    const regex = /^.{6,}$/;
    return regex.test(String(e));
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("password" in fieldValues) {
      if (fieldValues.password) {
        if (!validatePassword(fieldValues.password)) {
          temp.password = "Password is invalid";
        } else {
          temp.password = "";
        }
      } else {
        temp.password = "Password is required";
      }
    }

    if ("confirm_password" in fieldValues) {
      if (fieldValues.confirm_password) {
        if (!validatePassword(fieldValues.confirm_password)) {
          temp.confirm_password = "Password confirmation is invalid";
        } else {
          if (fieldValues.confirm_password !== values.password) {
            console.log(values.password);
            temp.confirm_password =
              "Password and Password confirmation is not equal";
          } else {
            temp.confirm_password = "";
          }
        }
      } else {
        temp.confirm_password = "Password is required";
      }
    }

    setErrors({ ...temp });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      setLoading(true);

      httpClient
        .get(`/contact/resetpassword/${props.id}/${values.confirm_password}`)
        .then((response) => {
          setLoading(false);
          props.isFormSubmitted();
        })
        .catch((err) => {
          setLoading(false);
          console.log("error forgot password");
          toast.error("ERROR: Forgot password request failed");
        });
    } else {
      console.log("form is not valid");
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <MailOutlineIcon />
        </Avatar>
        <Typography component="h2" variant="h6">
          Reset Password{" "}
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="password"
            label="Enter Your New Password"
            name="password"
            autoComplete="password"
            autoFocus
            value={values.password}
            onChange={handleInputChange}
            {...(errors.password && {
              error: true,
              helperText: errors.password,
            })}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="confirm_password"
            label="Confirm Your New Password"
            name="confirm_password"
            autoComplete="password"
            value={values.confirm_password}
            onChange={handleInputChange}
            {...(errors.confirm_password && {
              error: true,
              helperText: errors.confirm_password,
            })}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
          >
            {loading && (
              <CircularProgress
                size={14}
                style={{ left: 5, position: "absolute", zIndex: "999999" }}
              />
            )}
            Save
          </Button>
        </form>
      </Paper>
    </Container>
  );
}
