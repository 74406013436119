import { combineReducers } from "redux";

import { authentication } from "./authReducer";
import { submissions } from "./submissionReducer";
import { historySubmissions } from "./history.reducer";
import { accounts, processors, transporters } from "./accountReducer";
import { locations, collectionSites } from "./location.reducer";
import {
  claims,
  submissionClaims,
  materialCategories,
  containerTypes,
  bulbTypes,
  serviceTypes,
  units,
} from "./claimReducer";
import { contacts } from "./contact.reducer";
import { files, upload, documents } from "./file.reducer";
import { announcements } from "./announcement.reducer";

const rootReducer = combineReducers({
  authentication,
  submissions,
  historySubmissions,
  accounts,
  processors,
  transporters,
  locations,
  collectionSites,
  claims,
  submissionClaims,
  contacts,
  files,
  upload,
  documents,
  materialCategories,
  containerTypes,
  bulbTypes,
  serviceTypes,
  units,
  announcements,
});

export default rootReducer;
