import { userConstants } from "../_constants";
import { userService } from "../_services";
import { history } from "../_helpers";
import { toast } from "react-toastify";

export const userActions = {
    login,
    logout,
};

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                user => { 
                    dispatch(success(user));
                    history.push('/account');
                },
                error => {
                    toast.error("Failed to login");
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    //return { type: userConstants.LOGOUT };
}

// function login(username, password) {
//     return async (dispatch) => {
//         let apiEndpoint = "auths";
//         let payload = {
//             username,
//             password,
//         };
//         //incapsulate this code to api request
//         localStorage.setItem("token", "0000-0000-0000-0001");
//         localStorage.setItem("auth", true);
//         dispatch(loginUser({ token: "0000-0000-0000-0001", auth: true }));
//         history.push("/home");
//         //incapsulate this code to api request
//     };
// }

// function logout() {
//     return (dispatch) => {
//         localStorage.removeItem("token");
//         localStorage.removeItem("auth");
//         dispatch(logoutUser());
//         history.push("/");
//     };
// }

// export function loginUser(user) {
//     return {
//         type: "LOGIN_SUCCESS",
//         auth: user.auth,
//         token: user.token,
//     };
// }

// export function logoutUser() {
//     return {
//         type: "LOGOUT_SUCCESS",
//         auth: false,
//         token: "",
//     };
// }
