export const locationConstants = {
    GET_ALL_REQUEST: "LOCATION_GET_ALL_REQUEST",
    GET_ALL_SUCCESS: "LOCATION_GET_ALL_SUCCESS",
    GET_ALL_FAILURE: "LOCATION_GET_ALL_FAILURE",

    GET_BYID_REQUEST: "LOCATION_GET_BYID_REQUEST",
    GET_BYID_SUCCESS: "LOCATION_GET_BYID_SUCCESS",
    GET_BYID_FAILURE: "LOCATION_GET_BYID_FAILURE",

    GET_COLLECTIONSITES_REQUEST: "GET_COLLECTIONSITES_REQUEST",
    GET_COLLECTIONSITES_SUCCESS: "GET_COLLECTIONSITES_SUCCESS",
    GET_COLLECTIONSITES_FAILURE: "GET_COLLECTIONSITES_FAILURE",
};
