import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { submissionActions } from "../../../_actions";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  FormRoot: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "29%",
      color: theme.palette.text.secondary,
    },
  },
}));

const SubmissionDetails = (props) => {
  let sub = props.data;

  const classes = useStyles();
  return (
    <React.Fragment>
      {props.isLoading && (
        <CircularProgress
          size={20}
          style={{ position: "absolute", zIndex: "999999", left: "95%" }}
        />
      )}
      <form className={classes.FormRoot} noValidate autoComplete="off">
        <div>
          <TextField
            InputProps={{
              readOnly: true,
            }}
            id="standard-disabled"
            label="Sub No"
            value={sub.submissionNumber}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            InputProps={{
              readOnly: true,
            }}
            id="standard-disabled"
            label="Title"
            value={sub.name}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            InputProps={{
              readOnly: true,
            }}
            id="standard-disabled"
            label="Date"
            value={sub.submissionDate}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            InputProps={{
              readOnly: true,
            }}
            id="standard-disabled"
            label="Status"
            value={sub.statusCode}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            InputProps={{
              readOnly: true,
            }}
            id="standard-disabled"
            label="Amount"
            value={sub.totalAmount}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            InputProps={{
              readOnly: true,
            }}
            id="standard-disabled"
            label="Submitted By"
            value={sub.contactFullName}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </form>
    </React.Fragment>
  );
};

export default SubmissionDetails;
