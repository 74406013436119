import React, { Fragment, useState } from "react";
import MaterialTable from "material-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import { forwardRef } from "react";
import { Paper } from "@material-ui/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Backdrop from "@material-ui/core/Backdrop";
import LinearProgress from "@material-ui/core/LinearProgress";
import ConfirmDialog from "../../ConfirmDialog";
import { orange, blue, purple, red } from "@material-ui/core/colors";
import {
  submissionActions,
} from "../../../_actions";
import { useDispatch, useSelector } from "react-redux";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const SubmissionList = (props) => {

  const [duplicateConfirmOpen, setDuplicateConfirmOpen] = useState(false);
  const [selectedSubmissionId, setSelectedSubmissionId] = useState(props.selected);
  const dispatch = useDispatch();

  const onSelectedRow = (event, selectedRow) => {
    console.log(selectedRow);
    console.log(selectedRow?.id);
    setSelectedSubmissionId(selectedRow?.id);
    props.onRowClick(event, selectedRow);
  };

  const onDuplicateConfirmHandler = () => {
    console.log(selectedSubmissionId);
    dispatch(submissionActions.duplicateSubmission(selectedSubmissionId));
  };

  return (
    <React.Fragment>
      {/* {props.isLoading && (
        <CircularProgress
          size={20}
          style={{ position: "absolute", zIndex: "999999" }}
        />
      )} */}
      <MaterialTable
        icons={tableIcons}
        isLoading={props.isLoading}
        title=""
        columns={props.columns}
        data={props.data}
        onRowClick={onSelectedRow}
        options={{
          actionsColumnIndex: -1,
          paging: props.paging,
          maxBodyHeight: 250,
          rowStyle: (rowData) => ({
            backgroundColor: props.selected === rowData.id ? "#EEE" : "#FFF",
          }),
        }}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        actions={[
          (rowData) => ({
            icon: () => <FileCopyIcon style={{ color: orange[500] }} />,
            tooltip: "Duplicate Submission",
            onClick: (event, rowData) => {
              setDuplicateConfirmOpen(true);
              //setActiveSubmission(rowData);
            },
            disabled: selectedSubmissionId !== rowData.id,
            //disabled: rowData.id !== selectedSubmissionId,
          }),
        ]}
      />
      <ConfirmDialog
        title="Duplicate Submission"
        open={duplicateConfirmOpen}
        setOpen={setDuplicateConfirmOpen}
        onConfirm={onDuplicateConfirmHandler}
      >
        This action can't be reverted!
        <p>
          It will <u>DUPLICATE</u> submission{" "}
          with all their claims
        </p>
        <p>
          Please click{" "}
          <u>
            <b>Yes</b>
          </u>{" "}
          if you want to proceed.
        </p>
      </ConfirmDialog>
    </React.Fragment>
  );
};

export default SubmissionList;
