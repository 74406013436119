export const accountConstants = {
    GET_ALL_REQUEST: "ACCOUNT_GET_ALL_REQUEST",
    GET_ALL_SUCCESS: "ACCOUNT_GET_ALL_SUCCESS",
    GET_ALL_FAILURE: "ACCOUNT_GET_ALL_FAILURE",

    GET_BYID_REQUEST: "ACCOUNT_GET_BYID_REQUEST",
    GET_BYID_SUCCESS: "ACCOUNT_GET_BYID_SUCCESS",
    GET_BYID_FAILURE: "ACCOUNT_GET_BYID_FAILURE",

    ACCOUNT_GET_TRANSPORTERS_REQUEST: "ACCOUNT_GET_TRANSPORTERS_REQUEST",
    ACCOUNT_GET_TRANSPORTERS_SUCCESS: "ACCOUNT_GET_TRANSPORTERS_SUCCESS",
    ACCOUNT_GET_TRANSPORTERS_FAILURE: "ACCOUNT_GET_TRANSPORTERS_FAILURE",

    ACCOUNT_GET_PROCESSORS_REQUEST: "ACCOUNT_GET_PROCESSORS_REQUEST",
    ACCOUNT_GET_PROCESSORS_SUCCESS: "ACCOUNT_GET_PROCESSORS_SUCCESS",
    ACCOUNT_GET_PROCESSORS_FAILURE: "ACCOUNT_GET_PROCESSORS_FAILURE",
};
