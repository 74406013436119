import { accountConstants } from "../_constants";
import { accountService } from "../_services";
import { history } from "../_helpers";

export const accountActions = {
    getById,
    getProcessors,
    getTransporters,
};

function getById(id) {
    return (dispatch) => {
        dispatch(request());

        accountService.getById(id).then(
            (account) => dispatch(success(account)),
            (error) =>
                dispatch(failure("ERROR: Failed to fetch account details."))
        );
    };

    function request() {
        return { type: accountConstants.GET_BYID_REQUEST };
    }
    function success(account) {
        return { type: accountConstants.GET_BYID_SUCCESS, account };
    }
    function failure(error) {
        return { type: accountConstants.GET_BYID_FAILURE, error };
    }
}

function getProcessors() {
    return (dispatch) => {
        dispatch(request());

        accountService.getProcessors().then(
            (items) => dispatch(success(items)),
            (error) =>
                dispatch(failure("ERROR: Failed to fetch processors."))
        );
    };

    function request() {
        return { type: accountConstants.ACCOUNT_GET_PROCESSORS_REQUEST };
    }
    function success(items) {
        return { type: accountConstants.ACCOUNT_GET_PROCESSORS_SUCCESS, items };
    }
    function failure(error) {
        return { type: accountConstants.ACCOUNT_GET_PROCESSORS_FAILURE, error };
    }
}

function getTransporters() {
    return (dispatch) => {
        dispatch(request());
        accountService.getTransporters().then(
            (items) => dispatch(success(items)),
            (error) =>
                dispatch(failure("ERROR: Failed to fetch transporters."))
        );
    };

    function request() {
        return { type: accountConstants.ACCOUNT_GET_TRANSPORTERS_REQUEST };
    }
    function success(items) {
        return { type: accountConstants.ACCOUNT_GET_TRANSPORTERS_SUCCESS, items };
    }
    function failure(error) {
        return { type: accountConstants.ACCOUNT_GET_TRANSPORTERS_FAILURE, error };
    }
}
